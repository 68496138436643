import Vue from "vue";
import "./plugins/vuetify";
import vuetify from "./plugins/vuetify";
import router from "@/router";
import { store } from "@/store";
import VeeValidate from "vee-validate";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import App from "./App.vue";
import "leaflet/dist/leaflet.css";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";

import S2SCard from "@/components/S2SComponents/S2SCard.vue";
import S2SForm from "@/components/S2SComponents/S2SForm.vue";
import S2SNavigationMenus from "@/components/S2SComponents/S2SNavigationMenus.vue";
import S2SCurrencyInput from "@/components/S2SComponents/S2SCurrencyInput.vue";
import S2SFormGenerator from "@/components/S2SComponents/formGenerator/S2SFormGenerator.vue";

Vue.config.productionTip = false;
// Vue.config.performance = true;

// Initialise sentry
Sentry.init({
  dsn: `${process.env.VUE_APP_SENTRY_DSN}`,
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  environment: `${process.env.VUE_APP_ENVIRONMENT}`,
  release: process.env.PACKAGE_VERSION,
  beforeSend(options) {
    // Add loggedin user to sentry log
    options.user = store.state.auth.loggedInUser;
    return options;
  },
});

// Install vee validate and shop2shop components plugins
Vue.use(VeeValidate);
Vue.use(Chartkick.use(Chart));

Vue.component("S2SCard", S2SCard);
Vue.component("S2SForm", S2SForm);
Vue.component("S2SNavigationMenus", S2SNavigationMenus);
Vue.component("S2SCurrencyInput", S2SCurrencyInput);
Vue.component("S2SFormGenerator", S2SFormGenerator);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
