<template>
  <v-sheet elevation-0 class="background" height="100%">
    <v-toolbar :color="color">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <slot name="breadcrumbs"></slot>
      <v-spacer></v-spacer>
      <slot name="toolbar-content"></slot>
    </v-toolbar>
    <slot name="no-padding"></slot>
    <v-container fluid>
      <slot></slot>
    </v-container>
  </v-sheet>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    name: "S2SForm",

    props: {
      title: { type: String },
      color: { type: String },
      dark: { type: Boolean },
      light: { type: Boolean },
      fillHeight: { type: Boolean },
    },

    data: function () {
      return {
        items: [
          {
            text: "Dashboard",
            disabled: false,
            href: "breadcrumbs_dashboard",
          },
          {
            text: "Link 1",
            disabled: false,
            href: "breadcrumbs_link_1",
          },
          {
            text: "Link 2",
            disabled: true,
            href: "breadcrumbs_link_2",
          },
        ],
      };
    },
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
