import Vue from "vue";
import Vuex, { Store } from "vuex";
import { auth } from "@/store/auth";
import { shops } from "@/store/shops";
import { orders } from "@/store/orders";
import { accounts } from "@/store/accounts";
import { notifications } from "@/store/modules/notifications";
import { wsconnections } from "@/store/wsconnections";
import { RootState } from "./types";

Vue.use(Vuex);

/**
 * Plugin for vuex to watch for changes on active shop.
 * It will call all the refresh actions of all store if they have one
 */
const OnShopChangePlugin = (store: Store<RootState>) => {
  store.watch(
    (state) => state.shops.activeShopToken,
    (val, oldVal) => {
      // Don't do anything on init state
      if (!oldVal) return;

      for (let state in store.state) {
        const action = `${state}/refresh`;
        // If the store does not have an refresh action ignore
        if ((store as any)._actions[action]) store.dispatch(`${state}/refresh`);
      }
    }
  );
};

// Initial load of store Modules.
export const store = new Vuex.Store({
  modules: {
    auth,
    shops,
    orders,
    accounts,
    wsconnections,
    notifications,
  },
  plugins: [OnShopChangePlugin],
});
