<template>
  <div>
    <v-snackbar color="success" v-model="_success" top right>
      {{ status.success }}
      <v-btn color="primary" text @click="_success = false">Close</v-btn>
    </v-snackbar>
    <v-snackbar color="error" v-model="_error" top right>{{
      status.error
    }}</v-snackbar>
  </div>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    name: "Notifications",

    computed: {
      status: function () {
        return this.$store.state.notifications.status;
      },
      _error: {
        get: function () {
          return this.status["error"];
        },
        set: function (value) {
          if (value === true) return;
          this.$store.dispatch("notifications/clearStatus");
        },
      },
      _success: {
        get: function () {
          return this.status["success"];
        },
        set: function (val) {
          if (val === true) return;
          this.$store.dispatch("notifications/clearStatus");
        },
      },
    },
  });
</script>

<style scoped></style>
