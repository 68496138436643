<template>
  <v-app-bar
    color="primary"
    dark
    app
    :clipped-left="$vuetify.breakpoint.mdAndUp"
    fixed
  >
    <v-app-bar-nav-icon
      @click.stop="$emit('update:drawer', !drawer)"
    ></v-app-bar-nav-icon>
    <v-toolbar-title class="bold">Supplier Dashboard</v-toolbar-title>
    <v-layout row wrap justify-center>
      <div
        v-if="!balanceLoading"
        class="sub-heading d-flex align-center"
        @click="$router.push({ name: 'transaction-history' })"
        style="cursor: pointer"
      >
        <img
          src="/img/icons/camel.svg"
          style="height: 40px; width: 40px"
          class="mx-2 lign-middel"
        />
        <span class="bold">{{ balance }}</span>
      </div>
      <div v-else class="sub-heading">Balance Loading...</div>
    </v-layout>
    <v-toolbar-items>
      <!-- TODO: move this layout code to its own component -->
      <v-menu offset-y left>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            <span v-if="$vuetify.breakpoint.smAndUp" class="pr-3 bold">{{
              activeShop.name
            }}</span>
            <v-avatar size="40px">
              <img
                v-if="activeShop.profile && activeShop.profile.imageThumb"
                :src="`${imgBaseUrl}${activeShop.profile.imageThumb}`"
              />
              <v-icon v-else dark large>account_circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list class="py-0" two-line>
            <v-list-item
              :to="{ name: 'settings', params: { activeTab: 'profile' } }"
            >
              <v-list-item-avatar>
                <v-icon large>account_circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="loggedInUser.email">{{
                  loggedInUser.email
                }}</v-list-item-title>
                <v-list-item-subtitle v-if="loggedInUser.name">{{
                  loggedInUser.name
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-container @click.stop pt-3>
            <v-text-field
              label="Filter By Shop Name or Code"
              v-model="searchString"
              append-icon="search"
              @keydown.enter="newSearch"
              @click:append="newSearch"
              persistent-hint
              hint="Shop not found? Just click the search icon or hit enter."
              :loading="loadShops"
            ></v-text-field>

            <v-list style="max-height: 250px" class="overflow-y-auto">
              <v-fade-transition group>
                <v-list-item
                  ripple
                  @click="onShopSelected(shop)"
                  v-for="shop in filterByNameOrCode()"
                  :key="shop.id"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="shop.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-fade-transition>
            </v-list>
          </v-container>
          <v-divider></v-divider>
          <v-list class="py-0">
            <v-list-item @click="logout()">
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
  import Vue from "vue";
  import { Utils } from "@/utils";

  export default Vue.extend({
    name: "Toolbar",

    props: {
      drawer: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      searchedShops: function () {
        return this.$store.state.shops.searchedShops;
      },
      activeShop: function () {
        return this.$store.state.shops.activeShop;
      },
      myShops: function () {
        return this.$store.state.shops.myShops;
      },
      loading: function () {
        return this.$store.state.shops.loading;
      },
      items: function () {
        if (!this.myShops) return [];
        return this.myShops.map((entry) => {
          const name = entry.name;
          return Object.assign({}, entry, { name });
        });
      },
      loggedInUser: function () {
        return this.$store.state.auth.loggedInUser;
      },
      balance: function () {
        return this.$store.state.accounts.balance;
      },
      balanceLoading: function () {
        return this.$store.state.accounts.balanceLoading;
      },
    },

    data: function () {
      return {
        searchString: "",
        loadShops: false,
        imgBaseUrl: process.env.VUE_APP_IMG_BASE,
      };
    },

    watch: {
      myShops: function () {
        this.loadShops = false;
      },
    },

    mounted: function () {
      this.$store.dispatch("shops/fetchMyShops", "");
    },

    methods: {
      formatCurrency(text) {
        return Utils.formatText(text, Utils.TextType.CURRENCY);
      },
      onShopsLoaded() {
        this.loadShops = false;
      },
      filterByNameOrCode() {
        return this.myShops.filter((shop) => {
          return (
            shop.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !==
              -1 ||
            shop.code.toLowerCase().indexOf(this.searchString.toLowerCase()) !==
              -1
          );
        });
      },
      onShopSelected(shop) {
        this.$store.dispatch("shops/setActiveShop", shop.id);
        // resest list here
        this.$store.dispatch("shops/fetchMyShops", "");
        // switching happens here
        this.$store.dispatch("shops/switchShop");
      },
      newSearch() {
        this.loadShops = true;
        this.$store.dispatch(
          "shops/fetchMyShops",
          encodeURIComponent(this.searchString)
        );
      },
      logout() {
        this.$store.dispatch("auth/logout");
      },
    },
  });
</script>

<style scoped>
  .sub-heading span {
    font-size: 24px;
  }
  .bold {
    font-weight: bold;
  }
</style>
