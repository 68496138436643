import { GetterTree } from "vuex";
import { OrdersState, isOrderActionable, BoxSizes } from "@/store/orders/types";
import { RootState } from "@/store/types";

export const getters: GetterTree<OrdersState, RootState> = {
  total(state) {
    return state.pagination.totalItems;
  },
  ordersByStatus(state) {
    return (
      orderStatusId: number,
      paymentStatus?: "PAID" | "UNPAID",
      orgId?: number
    ) => {
      if (!state.ordersByStatus[orderStatusId]) return [];
      return state.ordersByStatus[orderStatusId].orders;
    };
  },
  // Might be userd by active order on a later stage
  paginationByStatus(state) {
    return (orderStatusId: number) => {
      return state.ordersByStatus[orderStatusId].pagination;
    };
  },
  activeOrdersStatus(state) {
    return state.orderStatusses.filter((statusItem) => {
      for (let status of state.activeOrderIds)
        if (status === statusItem.id) return true;
    });
  },
  // Get All Order statusses except "new"
  orderStatusses(state) {
    const tempIgnoredStatus = [1, 10, 11, 12, 14, 15];
    return state.orderStatusses.filter((statusItem) => {
      return tempIgnoredStatus.indexOf(statusItem.id) === -1 ? true : false;
    });
  },
  // Get statusses for unpaid view
  orderStatussesUnpaid(state) {
    const tempIgnoredStatus = [1, 9, 10, 11, 12, 14, 15];
    return state.orderStatusses.filter((statusItem) => {
      return tempIgnoredStatus.indexOf(statusItem.id) === -1 ? true : false;
    });
  },
  // Get statusses for unpaid view
  orderStatussesPaid(state) {
    const tempIgnoredStatus = [1, 9, 10, 11, 12, 14, 15];
    return state.orderStatusses.filter((statusItem) => {
      return tempIgnoredStatus.indexOf(statusItem.id) === -1 ? true : false;
    });
  },
  orderStatusColor() {
    return (orderStatusId: number, actionable?: boolean) => {
      switch (orderStatusId) {
        case 1:
        case 6:
          return "#33404D";
        case 12:
          return "primary";
        case 2:
          return "light-blue";
        case 7:
          return "accent";
        case 3:
          return "green";
        case 5:
          return "orange";
        case 8:
          return "success";
        case 4:
        case 9:
          return "error";
      }
    };
  },
  isOrderActionable() {
    return (orderStatusId: number) => {
      return isOrderActionable(orderStatusId);
    };
  },

  deliverySettings(state) {
    return state.deliverySettings;
  },

  deliveryRestrictions(state) {
    return state.deliveryRestrictions;
  },

  getAllowedBoxSizes(state) {
    let allowedSizes = state.allowedBoxSizes.filter((size: any) => {
      return size != null;
    });

    return allowedSizes;
  },

  allowedBoxSizesArray(state) {
    return state.allowedBoxSizes;
  },

  getSelectedOrder(state) {
    return state.selectedOrder;
  },

  /* dashboardGraphData(state) {
		return state.dashboardData;
	} */
};
