import { MutationTree } from "vuex";
import { OrdersState } from "@/store/orders/types";

export const mutations: MutationTree<OrdersState> = {
  setOrders(state, payload) {
    state.orders = payload;
    state.loading = false;
    state.totalItems = payload.length;
  },

  setOrderTotal(state, payload) {
    state.totalItems = payload;
  },

  setSelectedOrder(state, payload) {
    state.selectedOrder = payload;
    state.loading = false;
  },

  setSelectedOrderAddress(state, payload) {
    state.orderAddress = payload;
  },

  setActiveOrders(state, payload) {
    state.ordersByStatus = {};
    for (let i = 0; i < payload.length; i++) {
      if (!state.ordersByStatus[payload[i].status.id])
        state.ordersByStatus[payload[i].status.id] = { orders: [] };

      state.ordersByStatus[payload[i].status.id].orders.push(payload[i]);
    }

    state.loading = false;
  },

  setOrderStatusses(state, payload) {
    state.orderStatusses = payload;
  },

  setOrderStatusOverview(state, payload) {
    state.orderStatusOverview = payload;
  },

  setOrderTransactions(state, payload) {
    state.orderTransactions = payload;
  },

  setDeliverySettings(state, payload) {
    state.deliverySettings = payload;
  },

  setDeliveryRestrictions(state, payload) {
    state.deliveryRestrictions = payload;
  },

  setProviders(state, payload) {
    state.providers = payload;
  },

  setBoxSizes(state, payload) {
    state.boxSizes = payload;
  },

  setAllowedSizes(state, payload) {
    state.allowedBoxSizes = payload;
  },

  setDashboardData(state, payload) {
    if (payload.group === "status") {
      // sort by status
      const sortBy = [
        "Pending",
        "Accepted",
        "Ready",
        "In Transit",
        "New",
        "Completed",
        "Rejected",
      ];
      const sortField = "description";
      const data = payload.data.segments;

      // sort object
      const sortByObject: any = sortBy.reduce((obj, item, index) => {
        return {
          ...obj,
          [item]: index,
        };
      }, {});

      // sort by sort object
      const sortedResult = data.sort((a: any, b: any) => {
        return sortByObject[a[sortField]] - sortByObject[b[sortField]];
      });

      state.dashboardData[payload.group] = sortedResult;
    } else {
      state.dashboardData[payload.group] = payload.data;
    }
  },

  /* Grid Filters */
  setFilters(state, payload) {
    state.filters = { ...state.filters, ...payload };
  },

  /* Paging */
  paginate(state, payload) {
    state.pagination = payload;
  },

  setTotalItems(state, payload) {
    state.totalItems = payload;
  },

  loading(state, payload = true) {
    state.loading = payload;
  },
};
