import { store } from "@/store";
import { generateUrlWS } from "./http-common";

export class WSConnectionsApi {
  static async openBalanceConnection() {
    return new WebSocket(
      generateUrlWS(
        `organisation/${store.state.shops.activeShop.id}/balance/access_token/${store.state.auth.token}`
      )
    );
  }
}
