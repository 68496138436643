import { format } from "date-fns";
import { captureException } from "@sentry/core";
export namespace Utils {
  export function createCookie(name: string, value: string, days: number) {
    let expires;
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    } else {
      expires = "";
    }
    document.cookie = `${name}=${value}${expires}`;
  }

  export function getCookie(cname: string): string {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  export function checkCookie(cname: string): boolean {
    const cookie = getCookie(cname);
    return !!cookie;
  }

  export interface GridHeader {
    text: string;
    value: string;
    type?: "date" | "dateTime" | "currency";
    sortable?: boolean;
    class?: string | string[];
    width?: string;
  }

  export function formatColumn(header: any, item: any) {
    const nestedData = header.value.split(".");
    let columnVal = JSON.parse(JSON.stringify(item));

    while (nestedData.length) {
      if (!columnVal) return "-";
      columnVal = columnVal[nestedData.shift()];
    }

    if (!columnVal) return "-";

    if (header.type === "date")
      return formatText(columnVal, TextType.DATE_YYYY_MM_DD);
    if (header.type === "dateTime")
      return formatText(columnVal, TextType.DATE_TIME);
    if (header.type === "currency")
      return formatText(columnVal, TextType.CURRENCY);
    return columnVal;
  }

  export enum TextType {
    CURRENCY,
    DATE_YYYY_MM_DD,
    DATE_TIME,
  }

  export function formatText(text: string, textType: TextType) {
    switch (textType) {
      case TextType.CURRENCY:
        if (!text)
          return Intl.NumberFormat("en-ZA", {
            style: "currency",
            currency: "ZAR",
          }).format(0);
        return Intl.NumberFormat("en-ZA", {
          style: "currency",
          currency: "ZAR",
        }).format(parseFloat(text));
      case TextType.DATE_YYYY_MM_DD:
        return format(text, "YYYY-MM-DD");
      case TextType.DATE_TIME:
        return format(text, "YYYY-MM-DD HH:mm");
    }
  }

  interface logCustomException {
    name: "Warning" | "Error" | "Info" | "Show Stopper";
    message: string;
  }

  /**
   * Help function that will log to sentry. It takes any native exception.
   * It would modify the backend response error into a readable sentry error
   */
  export function logSentry(error: any) {
    if (error.response) {
      error.message = error.response.data.message;
      error.name = error.response.data.error;
    }

    captureException(error);
  }

  export function logSentryCustom(customException: logCustomException) {
    captureException(customException);
  }
}
