import { ActionTree, Store } from "vuex";
import { RootState } from "@/store/types";
import { WSConnectionsState } from "@/store/wsconnections/types";
import { WSConnectionsApi } from "@/api/ws-connections";

export const actions: ActionTree<WSConnectionsState, RootState> = {
  async wsConnection({ commit, dispatch, state }) {
    try {
      if (state.wsconnections) {
        commit("WS_DISCONNECT", state.wsconnections);
      } else {
        const connection = await WSConnectionsApi.openBalanceConnection();
        connection.onopen = () => {
          commit("WS_CONNECT", connection);
        };
        connection.onmessage = ({ data }) => {
          dispatch("accounts/setBalance", data, { root: true });
        };
        connection.onclose = () => {
          commit("WS_DISCONNECT", connection);
          setTimeout(function () {
            dispatch("wsConnection");
          }, 5000); // Try to reconnect in 5 seconds
        };
        connection.onerror = (error) => {
          /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
          console.error("error", error);
        };
      }
    } catch (error) {
      /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
      console.error("error", error);
    }
  },
};
