import { MutationTree } from "vuex";
import { WSConnectionsState } from "@/store/wsconnections/types";

export const mutations: MutationTree<WSConnectionsState> = {
  WS_CONNECT(state, conn) {
    state.wsconnections = conn;
  },
  WS_DISCONNECT(state, conn) {
    state.wsconnections = undefined;
    conn.close();
  },
};
