import { Module } from "vuex";
import { getters } from "@/store/wsconnections/getters";
import { actions } from "@/store/wsconnections/actions";
import { mutations } from "@/store/wsconnections/mutations";
import { WSConnectionsState } from "@/store/wsconnections/types";
import { RootState } from "../types";

export const state: WSConnectionsState = {
  wsconnections: undefined,
};

const namespaced: boolean = true;

export const wsconnections: Module<WSConnectionsState, RootState> = {
  namespaced,
  state: state,
  getters,
  actions,
  mutations,
};
