import { Module } from "vuex";
import { getters } from "@/store/orders/getters";
import { actions } from "@/store/orders/actions";
import { mutations } from "@/store/orders/mutations";
import { RootState } from "@/store/types";
import {
  OrdersState,
  Order,
  Providers,
  DeliveryRestrictions,
} from "@/store/orders/types";
import { pagingDefault } from "../utils";
import { Address } from "../modules/addresses";

export const state: OrdersState = {
  orders: [],
  selectedOrder: {} as Order,
  orderAddress: {} as Address,
  activeOrderIds: [2, 3, 5, 6],
  ordersByStatus: {},
  orderStatusses: [],
  orderStatusOverview: [],
  providers: [],
  deliverySettings: {} as Providers,
  deliveryRestrictions: {} as DeliveryRestrictions,
  boxSizes: [],
  allowedBoxSizes: [],
  orderTransactions: [],
  dashboardData: {},
  loading: false,
  pagination: pagingDefault,
  totalItems: 0,
  filters: {
    fromDate: "",
    toDate: "",
    orderstatus: [],
    paymentstatus: [],
    orgsearch: "",
    sort: "date-",
  },
};

const namespaced: boolean = true;

export const orders: Module<OrdersState, RootState> = {
  namespaced,
  state: state,
  getters,
  actions,
  mutations,
};
