<template>
  <v-layout wrap v-bind="layout.properties" v-if="valid">
    <v-flex
      v-for="(field, index) in formFields"
      :key="index"
      v-show="!field.hidden"
      v-bind="applyProperties(field)"
      px-2
    >
      <v-text-field
        v-if="field.component === 'v-text-field'"
        :label="field.label"
        v-bind="field.properties"
        v-validate="field.validation"
        :data-vv-name="field.name"
        :error-messages="errors.collect(field.name)"
        :value="getValue(field.name)"
        @input="onInput($event, field.name)"
      ></v-text-field>
      <v-textarea
        v-if="field.component === 'v-textarea'"
        :label="field.label"
        v-bind="field.properties"
        v-validate="field.validation"
        :data-vv-name="field.name"
        :error-messages="errors.collect(field.name)"
        :value="getValue(field.name)"
        @input="onInput($event, field.name)"
        :auto-grow="field.autogrow"
        :rows="field.rows"
      ></v-textarea>
      <v-file-input
        v-else-if="field.component === 'v-file-uploader'"
        :label="field.label"
        v-bind="field.properties"
        v-validate="field.validation"
        :data-vv-name="field.name"
        :error-messages="errors.collect(field.name)"
        :value="getValue(field.name)"
        @change="onInput($event, field.name)"
      ></v-file-input>
      <v-select
        v-else-if="field.component === 'v-select'"
        :label="field.label"
        :items="lookups[field.name]"
        :value="getValue(field.name)"
        @input="onInput($event, field.name)"
        v-bind="field.properties"
        v-validate="field.validation"
        :data-vv-name="field.name"
        :error-messages="errors.collect(field.name)"
      ></v-select>
      <v-checkbox
        v-else-if="field.component === 'v-checkbox'"
        :input-value="getValue(field.name)"
        @change="onInput($event, field.name)"
        :label="field.label"
        v-bind="field.properties"
      ></v-checkbox>
      <v-autocomplete
        v-else-if="field.component === 'v-autocomplete'"
        :label="field.label"
        v-bind="field.properties"
        :value="getValue(field.name)"
        @input="onInput($event, field.name)"
        :search-input.sync="search[field.name]"
        :items="lookups[field.name]"
        v-validate="field.validation"
        :data-vv-name="field.name"
        :error-messages="errors.collect(field.name)"
      ></v-autocomplete>
      <v-menu
        v-else-if="field.component === 'v-date-picker'"
        v-model="menu[field.name]"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            :value="getValue(field.name)"
            :label="field.label"
            prepend-icon="event"
            readonly
          ></v-text-field>
        </template>
        <v-date-picker
          :value="getValue(field.name)"
          @input="onInput($event, field.name)"
          no-title
          scrollable
        ></v-date-picker>
      </v-menu>
      <label v-else-if="field.component === 'v-label'">{{ field.label }}</label>
      <slot
        v-else-if="field.component === 'v-slot'"
        :name="field.slotName"
        :model="model"
      ></slot>
    </v-flex>
  </v-layout>
</template>

<script>
  import Vue from "vue";
  import formGeneratorSchema from "@/components/S2SComponents/formGenerator/formGeneratorSchema.json";
  import {
    ComponentBase,
    ComponentType,
    Form,
    Layout,
  } from "@/components/S2SComponents/formGenerator/formGeneratedTypes.ts";
  import Ajv from "ajv";
  import VeeValidate from "vee-validate";

  Vue.use(VeeValidate);

  export default Vue.extend({
    name: "S2SFormGenerator",

    props: {
      title: {
        type: String,
      },
      apiLookup: {
        type: [Promise, Function],
        default: () => {},
      },
      schema: {
        type: Object,
        default: () => {},
      },
      data: {
        type: Object,
        default: () => {},
      },
    },

    data: function () {
      return {
        formFields: [],
        layout: Layout,
        valid: false,

        model: {},
        menu: {},
        date: {},
        search: {}, // For the components with the search attribute

        lookups: [],

        formGeneratorSchema: formGeneratorSchema,
        schemaForm: Form,

        // Default Break Points
        defaultBreakPoints: { md6: true, xs12: true },

        attributes: {},
      };
    },

    watch: {
      data: {
        immediate: true,
        deep: true,
        handler() {
          if (!this.data) return;
          this.model = this.data;
        },
      },
    },

    methods: {
      onInput(value, fieldName) {
        this.setValue(fieldName, value);
      },
      async fetchLookups() {
        for (let field of this.formFields) {
          if (
            field.component !== "v-slot" &&
            (field.component === "v-select" ||
              field.component === "v-autocomplete")
          ) {
            if (typeof field.items === "string") {
              const response = await this.apiLookup(field.items);
              if (!response.data) {
                Vue.set(this.lookups, field.name, response.sort());
              } else {
                const data = response.data.results
                  ? response.data.results
                  : response.data;
                Vue.set(this.lookups, field.name, data.sort());
              }
            } else {
              Vue.set(this.lookups, field.name, field.items);
            }
          }
        }
      },
      isObject(item) {
        return item && typeof item === "object" && !Array.isArray(item);
      },
      mergeDeep(target, ...sources) {
        if (!sources.length) return target;
        const source = sources.shift();

        if (this.isObject(target) && this.isObject(source)) {
          for (const key in source) {
            if (this.isObject(source[key])) {
              if (!target[key]) Object.assign(target, { [key]: {} });
              this.mergeDeep(target[key], source[key]);
            } else {
              Object.assign(target, { [key]: source[key] });
            }
          }
        }

        return this.mergeDeep(target, ...sources);
      },
      buildObjectFromString(keyString, defaultVal) {
        let str = keyString,
          arr = str.split("."),
          obj,
          o = (obj = {});

        for (let i = 0; i < arr.length; i++) {
          if (arr.length === i + 1) o = o[arr[i]] = defaultVal;
          else o = o[arr[i]] = {};
        }

        // We doing deep merge because a nested object can contain multiple values belonging to multiple components
        this.model = this.mergeDeep(this.model, obj);
      },
      buildDefaultValues() {
        for (let field of this.formFields) {
          // We always want checkboxes to be defaulted to false!
          if (field.component === "v-checkbox" && !this.getValue(field.name))
            this.buildObjectFromString(field.name, false);
          else if (field.component !== "v-slot" && !this.getValue(field.name))
            this.buildObjectFromString(field.name, field.defaultVal);
          else if (
            field.component === "v-slot" &&
            !this.getValue(field.slotName)
          )
            this.buildObjectFromString(field.slotName, field.defaultVal || {});
        }
      },
      validate() {
        return this.$validator.validateAll();
      },
      buildValidationDictionary() {
        for (let i = 0; i < this.formFields.length; i++) {
          const component = this.formFields[i];
          if (component.component === "v-slot") continue; // For the time being we wont build validation dictionary for slot component

          this.attributes[component.name] = component.label;
        }
        const dictionary = {
          ...this.attributes,
        };
        return dictionary;
      },
      applyProperties(field) {
        let properties = field.containerProperties;
        if (!field.breakpoints)
          properties = { ...properties, ...this.defaultBreakPoints };
        else properties = { ...properties, ...field.breakpoints };

        return properties;
      },
      getValue(fieldName) {
        return fieldName
          .split(".")
          .reduce(
            (obj, key) =>
              obj && obj[key] !== "undefined" ? obj[key] : undefined,
            this.model
          );
      },
      setValue(path, value) {
        let schema = this.model; // a moving reference to internal objects within obj
        const pList = path.split(".");
        const len = pList.length;
        for (let i = 0; i < len - 1; i++) {
          const elem = pList[i];
          if (!schema[elem]) schema[elem] = {};
          schema = schema[elem];
        }
        this.$set(schema, pList[len - 1], value);
        this.$forceUpdate();
      },
    },

    async mounted() {
      const ajv = new Ajv();
      const validate = ajv.compile(this.formGeneratorSchema);

      this.valid = validate(this.schema);

      if (!this.valid && validate.errors)
        throw Error(JSON.stringify(validate.errors));
      // We need some proper error notification here

      this.schemaForm = this.schema;
      this.formFields = Object.freeze(this.schemaForm.fields);

      this.layout = this.schemaForm.layout;
      await this.fetchLookups();
      this.buildDefaultValues();

      this.valid = true;

      this.$validator.localize("en", this.buildValidationDictionary());
    },
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
