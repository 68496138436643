<template>
  <v-card>
    <v-toolbar
      ref="toolbar"
      dense
      v-if="title || hasSlot('toolbar-content')"
      color="#EEEEEE"
      flat
    >
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer v-if="title"></v-spacer>
      <slot name="toolbar-content"></slot>
    </v-toolbar>
    <v-container grid-list-xs>
      <slot></slot>
    </v-container>
  </v-card>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    name: "S2SCard",

    props: {
      title: {
        type: String,
      },
      elevation: {
        type: [Number, String],
        default: 0,
      },
    },

    computed: {
      computeElevation() {
        return `elevation-${this.elevation}`;
      },
    },

    methods: {
      hasSlot(name) {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
      },
    },
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
