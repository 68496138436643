import { MutationTree } from "vuex";
import { ShopsState } from "@/store/shops/types";

export const mutations: MutationTree<ShopsState> = {
  setActiveShop(state, payload) {
    state.activeShop = payload;
    state.loading = false;
  },

  setActiveShopToken(state, payload) {
    state.activeShopToken = payload;
    state.switchShop = false;
  },

  setMyShops(state, payload) {
    state.myShops = payload;
    state.loading = false;
  },

  setSearchedOrganisations(state, payload) {
    state.searchedOrganisations = payload;
    state.loading = false;
  },

  setSettings(state, payload) {
    state.settings = payload;
    state.loading = false;
  },
  setFees(state, payload) {
    state.fees = payload;
    state.loading = false;
  },
  setNotifications(state, payload) {
    state.notifications = payload;
    state.loading = false;
    state.switchShop = false;
  },

  setAvailableNotificationSettings(state, payload) {
    state.availableNotificationSubscriptions = payload;
    state.loading = false;
  },

  setDefaultNotificationSettings(state, payload) {
    state.defaultNotificationSubscriptions = payload;
    state.loading = false;
  },

  setAvailablePushySettings(state, payload) {
    state.availablePushySubscriptions = payload;
    state.loading = false;
  },

  setDefaultPushySettings(state, payload) {
    state.defaultPushySubscriptions = payload;
    state.loading = false;
  },

  setNotificationSettings(state, payload) {
    state.notificationSubscription = payload;
    state.loading = false;
  },

  loading(state, payload = true) {
    state.loading = payload;
  },

  switchShop(state, payload = true) {
    state.switchShop = payload;
  },
};
