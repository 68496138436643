import Vue from "vue";
// @ts-ignore
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

// @ts-ignore
export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: { base: "#0A2645" },
        secondary: { base: "#121F2C", lighten1: "#162738" },
        accent: { base: "#FAA225" },
        error: "#f44336",
        warning: "#FF9100",
        info: "#2196f3",
        success: "#4caf50",
        background: "#efefef",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
