import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import {
  Filters,
  OrdersState,
  Order,
  BoxSizes,
  Providers,
  DeliveryRestrictions,
} from "@/store/orders/types";
import { Paging, pagingDefault } from "@/store/utils";
import { OrdersApiService, OrderStatus } from "@/api/orders";
import { Utils } from "@/utils";
import { fetchTransaction } from "@/api/transactions";
import { Address } from "../modules/addresses";

export const actions: ActionTree<OrdersState, RootState> = {
  async fetchOrders({ commit, dispatch, state }) {
    try {
      commit("loading");
      const result = await OrdersApiService.fetchOrders(
        state.pagination,
        state.filters
      );
      commit("setOrders", result.data.results);
      commit("setTotalItems", result.data.count);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async fetchOrder({ commit, dispatch }, orderId) {
    try {
      commit("loading");
      const result = await OrdersApiService.fetchOrder(orderId);
      commit("setSelectedOrder", result.data);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async fetchOrderPickupAddress({ commit, dispatch }) {
    try {
      commit("loading");
      const result = await OrdersApiService.fetchOrderPickupAddress();
      let results = result.data.results.filter((address: Address) => {
        return address.isPrimary;
      });
      commit("setSelectedOrderAddress", results);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async fetchDashboardData({ commit, dispatch }, group: string) {
    try {
      commit("loading");
      const result = await OrdersApiService.fetchDashboardData(group);
      commit("setDashboardData", { group: group, data: result.data });
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async fetchTransaction({ commit }, orderId: number) {
    try {
      commit("loading");
      const result = await fetchTransaction(
        { page: undefined },
        { order_id: orderId }
      );
      commit("setOrderTransactions", result.data.results);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  clearSelectedOrder({ commit }) {
    commit("setSelectedOrder", {});
  },

  async fetchOrdersByStatus(
    { commit, state },
    data: {
      statusIds: Array<number>;
      paymentStatus: Array<string>;
      orgsearch?: string;
      pagination?: Paging;
    } = {
      statusIds: state.activeOrderIds,
      paymentStatus: ["PAID", "UNPAID"],
      orgsearch: "",
      pagination: {
        page: 0,
        itemsPerPage: 10,
        descending: false,
        sortBy: [],
        filters: {},
      },
    }
  ) {
    try {
      const filters: Filters = {
        orderstatus: data.statusIds,
        paymentstatus: data.paymentStatus,
        orgsearch: data.orgsearch,
        sort: "date-",
        salesOrder: true,
      };

      commit("loading");
      if (data.pagination == undefined) {
        data.pagination = {
          page: 0,
          itemsPerPage: 10,
          descending: false,
          sortBy: [],
          filters: {},
        };
      }

      const result = await OrdersApiService.fetchOrders(
        data.pagination,
        filters
      );
      commit("setOrders", result.data.results);
      commit("setOrderTotal", result.data.count);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async fetchOrderStatusses({ commit, state }) {
    try {
      if (state.orderStatusses.length > 0) return; // Order status is very constant. Dont need to call it again
      commit("loading");
      const result = await OrdersApiService.fetchOrderStatusses();
      commit("setOrderStatusses", result.data);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async fetchOrderStatusOverview(
    { commit, dispatch },
    data: {
      organisationId: number;
      paymentStatus?: Array<string>;
      orgsearch?: string;
    }
  ) {
    try {
      commit("loading");
      const result = await OrdersApiService.fetchOrderStatusOverview(
        data.organisationId,
        data.paymentStatus,
        data.orgsearch
      );
      let results = result.data.sort((a: { id: number }, b: { id: number }) =>
        a.id > b.id ? 1 : -1
      );
      commit("setOrderStatusOverview", results);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async fetchDeliverySettings({ commit, dispatch }) {
    try {
      commit("loading");
      const result = await OrdersApiService.fetchDeliverySettings();
      let results = result.data
        ? result.data
        : {
            fee: 50,
            warehouseId: "",
            provider: {
              id: 0,
              name: "",
            },
          };
      commit("setDeliverySettings", results);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async fetchDeliveryRestrictions({ commit, dispatch }) {
    try {
      commit("loading");
      const result = await OrdersApiService.fetchDeliveryRestrictions();

      commit("setDeliveryRestrictions", result.data);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async updateDeliveryRestrictions(
    { commit, dispatch },
    restrictions: DeliveryRestrictions
  ) {
    try {
      commit("loading");
      const result = await OrdersApiService.updateDeliveryRestrictions(
        restrictions
      );
      dispatch("notifications/success", "Delivery Restrictions Updated", {
        root: true,
      });
      commit("setDeliveryRestrictions", result.data);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async updateDeliveryFee({ commit }, fee: any) {
    try {
      commit("loading");
      await OrdersApiService.updateDeliveryFee(fee);
      commit("notifications/success", "Delivery Settings Updated", {
        root: true,
      });
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async updateRequireBoxes({ commit }, requireBoxes: boolean) {
    try {
      commit("loading");
      await OrdersApiService.updateRequireBoxes(requireBoxes);
      commit("notifications/success", "Delivery Settings Updated", {
        root: true,
      });
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async updateDeliveryProvider({ commit }, provider: Providers) {
    try {
      commit("loading");
      await OrdersApiService.updateDeliveryProvider(provider);
      commit("notifications/success", "Delivery Settings Updated", {
        root: true,
      });
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async fetchProviders({ commit, dispatch, state }) {
    try {
      if (state.providers.length > 0) return; // call once as providers does not change often
      commit("loading");
      const result = await OrdersApiService.fetchProviders();
      commit("setProviders", result.data);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async fetchBoxSizes({ commit, dispatch, state }) {
    try {
      if (state.boxSizes.length > 0) return; // call once as box sizes are constant
      commit("loading");
      const result = await OrdersApiService.fetchBoxSizes();
      let results = result.data.sort((a: any, b: any) =>
        a.id > b.id ? 1 : -1
      );
      commit("setBoxSizes", results);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async addBoxSizes({ commit, dispatch, state }, boxId: number) {
    try {
      commit("loading");
      await OrdersApiService.addBoxSizes(boxId);
      dispatch(
        "notifications/success",
        `Box size ${state.boxSizes[boxId - 1].name} added`,
        { root: true }
      );
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async removeBoxSizes({ commit, dispatch, state }, boxId: number) {
    try {
      commit("loading");
      await OrdersApiService.removeBoxSizes(boxId);
      dispatch(
        "notifications/success",
        `Box size ${state.boxSizes[boxId - 1].name} removed`,
        { root: true }
      );
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async fetchAllowedBoxSizes({ commit, dispatch }) {
    try {
      commit("loading");
      const result = await OrdersApiService.fetchAllowedBoxSizes();
      let results: Array<number> = [];
      result.data.forEach((size: BoxSizes) => {
        results[size.id - 1] = size.id;
      });
      commit("setAllowedSizes", results);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async changeOrderStatus(
    { commit, dispatch },
    data: {
      order: Order;
      statusIds: Array<number>;
      paymentStatus: Array<string>;
      orgsearch?: string;
      params?: any;
      pagination?: Paging;
    }
  ) {
    const getNextOrderStatus = (order: Order): OrderStatus => {
      switch (order.status.id) {
        case 2:
          return "accept";
        case 3:
          return "ready";
        case 5:
          if (order.deliveryType === "DELIVERY") return "in_transit";
          else return "collected";
        case 6:
          return "delivered";
        default:
          throw "No Such Status Id";
      }
    };

    try {
      commit("loading");
      await OrdersApiService.changeOrderStatus(
        data.order.id,
        getNextOrderStatus(data.order),
        data.params
      );
      commit(
        "notifications/success",
        `Order ${data.order.id} moved to ${getNextOrderStatus(data.order)}`,
        { root: true }
      );
      dispatch("fetchOrdersByStatus", data);
      dispatch("fetchOrder", data.order.id);
      dispatch("fetchOrderStatusOverview", {
        organisationId: this.state.shops.activeShop.id,
        paymentStatus: data.paymentStatus,
        orgsearch: data.orgsearch,
      });
    } catch (error) {
      dispatch(
        "notifications/error",
        `Unpaid orders may not transition to the collected or delivered order states Order[${data.order.id}]`,
        {
          root: true,
        }
      );
      commit("loading", false);
    }
  },

  async moveOrderBackwards(
    { commit, dispatch },
    data: {
      order: Order;
      statusIds: Array<number>;
      paymentStatus: Array<string>;
      orgsearch?: string;
      pagination?: Paging;
    }
  ) {
    const getPrevOrderStatus = (order: Order): OrderStatus => {
      switch (order.status.id) {
        case 3:
        case 9:
          return "pending";
        case 5:
          return "accept";
        case 6:
          return "ready";
        default:
          throw "No Such Status Id";
      }
    };

    try {
      commit("loading");
      await OrdersApiService.changeOrderStatus(
        data.order.id,
        getPrevOrderStatus(data.order)
      );
      commit(
        "notifications/success",
        `Order ${data.order.id} moved to ${getPrevOrderStatus(data.order)}`,
        { root: true }
      );
      dispatch("fetchOrdersByStatus", data);
      dispatch("fetchOrder", data.order.id);
      dispatch("fetchOrderStatusOverview", {
        organisationId: this.state.shops.activeShop.id,
        paymentStatus: data.paymentStatus,
        orgsearch: data.orgsearch,
      });
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async updateOrderItem(
    { commit, dispatch },
    orderItemUpdateObj: { orderItem: any; orderId: number }
  ) {
    try {
      commit("loading");
      const cleanUpdateObj = JSON.parse(
        JSON.stringify(orderItemUpdateObj.orderItem)
      );
      cleanUpdateObj.id = orderItemUpdateObj.orderItem.detail.id;
      delete cleanUpdateObj.unitPrice;
      delete cleanUpdateObj.markup;
      delete cleanUpdateObj.linePrice;
      delete cleanUpdateObj.status;
      delete cleanUpdateObj.detail;
      delete cleanUpdateObj.extras;
      delete cleanUpdateObj.options;
      delete cleanUpdateObj.instorePaymentRequired;

      // cleanUpdateObj.detail = {options}
      await OrdersApiService.updateOrderItem(
        orderItemUpdateObj.orderId,
        orderItemUpdateObj.orderItem.id,
        cleanUpdateObj
      );
      dispatch("fetchOrder", orderItemUpdateObj.orderId);
      commit("success", `Order has been updated`);
    } catch (error) {
      commit("error", error.response.data.message);
      Utils.logSentry(error);
    }
  },

  async addOrderGRN(
    { commit, dispatch },
    data: { orderId: number; grn: string }
  ) {
    try {
      commit("loading");
      await OrdersApiService.addOrderGrn(data.orderId, data.grn);
      dispatch("fetchOrder", data.orderId);
      commit(
        "notifications/success",
        `Order ${data.orderId} grn has been updated`,
        { root: true }
      );
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async declineOrder(
    { commit, dispatch },
    data: {
      order: Order;
      statusIds: Array<number>;
      paymentStatus?: Array<string>;
      orgsearch?: string;
      pagination?: Paging;
      message?: string;
    }
  ) {
    try {
      commit("loading");
      await OrdersApiService.declineOrder(data.order.id, data.message);
      dispatch("fetchOrdersByStatus", data);
      dispatch("fetchOrder", data.order.id);
      dispatch("fetchOrderStatusOverview", {
        organisationId: this.state.shops.activeShop.id,
        paymentStatus: data.paymentStatus,
        orgsearch: data.orgsearch,
      });
      commit(
        "notifications/success",
        `Order ${data.order.id} has been declined`,
        { root: true }
      );
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  refresh({ commit }) {
    commit("paginate", { ...pagingDefault });
  },

  paginate({ commit }, pagination) {
    commit("paginate", pagination);
  },

  setFilters({ commit }, filters: Filters) {
    // Axios does not support null parameters
    if (filters.fromDate === null) filters.fromDate = "";
    if (filters.toDate === null) filters.toDate = "";
    commit("setFilters", filters);
  },
};
