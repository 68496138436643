<template>
  <v-text-field
    name="amount"
    v-model="displayValue"
    :label="label"
    @blur="isInputActive = false"
    @focus="isInputActive = true"
  ></v-text-field>
</template>

<script>
  import Vue from "vue";

  export default Vue.extend({
    name: "S2SCurrencyInput",

    props: {
      symbol: {
        type: String,
        default: "R",
      },
      thousandsSeperator: {
        type: String,
        default: " ",
      },
      decimalCount: {
        type: Number,
        default: 2,
      },
      decimalFixed: {
        type: Boolean,
        default: true,
      },
      label: {
        type: String,
        default: "",
      },
      value: {
        type: Number,
        default: 0,
      },
    },

    data: function () {
      return {
        isInputActive: false,
      };
    },

    computed: {
      displayValue: {
        get: function () {
          if (this.isInputActive) {
            // Cursor is inside the input field. unformat display value for user
            return this.value.toString();
          } else if (this.decimalFixed) {
            // User is not modifying now. Format display value for user interface
            return (
              `${this.symbol} ` +
              (+this.value)
                .toFixed(this.decimalCount)
                .replace(
                  /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                  `$1${this.thousandsSeperator}`
                )
            );
          } else {
            return (
              `${this.symbol} ` +
              String(this.value).replace(
                /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                `$1${this.thousandsSeperator}`
              )
            );
          }
        },

        set: function (modifiedValue) {
          // Recalculate value after ignoring "R" and "," in user input
          let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
          // Ensure that it is not NaN
          if (isNaN(newValue)) {
            newValue = 0;
          }
          // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
          // $emit the event so that parent component gets it
          this.$emit("input", newValue);
          this.$emit("change", newValue);
        },
      },
    },
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
