import { render, staticRenderFns } from "./S2SNavigationMenus.vue?vue&type=template&id=3be734c5&scoped=true&"
import script from "./S2SNavigationMenus.vue?vue&type=script&lang=js&"
export * from "./S2SNavigationMenus.vue?vue&type=script&lang=js&"
import style0 from "./S2SNavigationMenus.vue?vue&type=style&index=0&id=3be734c5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be734c5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VList,VListGroup,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemTitle})
