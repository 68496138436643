import { ConnectionsState } from "./connections/types";
import { AuthState } from "@/store/auth/types";
import { ShopsState } from "@/store/shops/types";
import { ProductsState } from "@/store/products/types";
import { CategoriesState } from "@/store/categories/types";
import { ProductLabelsState } from "@/store/productLabels/types";
import { OrdersState } from "@/store/orders/types";
import { TransactionsState } from "./transactions/types";
import { store } from ".";
import { OptionsState } from "./catalogue/options";
import { AddressesState } from "./modules/addresses";

export interface RootState {
  auth: AuthState;
  shops: ShopsState;
  products: ProductsState;
  categories: CategoriesState;
  productLabels: ProductLabelsState;
  options: OptionsState;
  orders: OrdersState;
  transactions: TransactionsState;
  connections: ConnectionsState;
  addresses: AddressesState;
}

/* States that will be lazy loaded */
// TODO: we need to move this to a seperate module
export const importConnectionsState = () =>
  import("@/store/connections").then(({ connections }) => {
    if (!store.state.connections)
      store.registerModule("connections", connections);
    else return;
  });
export const importOrdersState = () =>
  import("@/store/orders").then(({ orders }) => {
    if (!store.state.orders) store.registerModule("orders", orders);
    else return;
  });
export const importTransactionsState = () =>
  import("@/store/transactions").then(({ transactions }) => {
    if (!store.state.transactions)
      store.registerModule("transactions", transactions);
    else return;
  });
export const importUsersState = () =>
  import("@/store/users").then(({ users }) => {
    store.registerModule("users", users);
  });
export const importAddressState = () =>
  import("@/store/modules/addresses").then(({ addresses }) => {
    if (!store.state.addresses) store.registerModule("addresses", addresses);
    else return;
  });
export const importAccountsState = () =>
  import("@/store/accounts").then(({ accounts }) => {
    store.registerModule("accounts", accounts);
  });
export const importProductsState = () =>
  import("@/store/products").then(({ products }) => {
    if (!store.state.products) store.registerModule("products", products);
    else return;
  });
export const importCategoriesState = () =>
  import("@/store/categories").then(({ categories }) => {
    if (!store.state.categories) store.registerModule("categories", categories);
    else return;
  });
export const importProductLabelsState = () =>
  import("@/store/productLabels").then(({ productLabels }) => {
    if (!store.state.productLabels)
      store.registerModule("productLabels", productLabels);
    else return;
  });
export const importOptionsState = () =>
  import("@/store/catalogue/options").then(({ options }) => {
    if (!store.state.options) store.registerModule("options", options);
    else return;
  });
