import { MutationTree } from "vuex";
import { AuthState } from "@/store/auth/types";

export const mutations: MutationTree<AuthState> = {
  setToken(state, payload) {
    state.token = payload;
  },
  setAuthenticated(state, payload) {
    state.isAuthenticated = payload;
  },
  setLoggedInUser(state, payload) {
    state.loggedInUser = payload;
  },
  setLogoutFunction(state, payload) {
    state.logout = payload;
  },
  setUpdateTokenFunction(state, payload) {
    state.updateToken = payload;
  },
};
