import Vue from "vue";
import Router, { Route } from "vue-router";
import {
  importConnectionsState,
  importTransactionsState,
  importOrdersState,
  importUsersState,
  importCategoriesState,
  importProductLabelsState,
  importProductsState,
  importOptionsState,
  importAddressState,
} from "./store/types";

const MyCustomers = () =>
  import(
    /* webpackChunkName: 'my-customers' */ "./views/customers/MyCustomers.vue"
  );
const PossibleConnections = () =>
  import(
    /* webpackChunkName: 'my-customers' */ "./views/customers/PossibleConnections.vue"
  );
const CustomerOrderHistory = () =>
  import(
    /* webpackChunkName: 'my-customers' */ "./views/customers/CustomerOrderHistory.vue"
  );
const PendingCustomers = () =>
  import(
    /* webpackChunkName: "pending-customers" */ "./views/customers/PendingCustomers.vue"
  );
const CustomerDiscount = () =>
  import(
    /* webpackChunkName: "customer-overview" */ "./views/customers/CustomerDiscount.vue"
  );
const CustomerSettings = () =>
  import(
    /* webpackChunkName: "customer-overview" */ "./views/customers/CustomerSettings.vue"
  );
const CustomerOverview = () =>
  import(
    /* webpackChunkName: "customer-overview" */ "./views/customers/MyCustomerOverview.vue"
  );
const CustomerOrder = () =>
  import(
    /* webpackChunkName: "customer-overview" */ "./views/customers/CustomerOrder.vue"
  );
const Accounts = () => import("./views/payments/Accounts.vue");
/** Catalogue */
const Products = () =>
  import(/* webpackChunkName: "products" */ "./views/catalogue/Products.vue");
const ProductCreate = () =>
  import(
    /* webpackChunkName: "products" */ "./views/catalogue/ProductCreate.vue"
  );
const ProductEdit = () =>
  import(
    /* webpackChunkName: "products" */ "./views/catalogue/ProductEdit.vue"
  );
const ProductLabels = () =>
  import(
    /* webpackChunkName: "productLabels" */ "./views/catalogue/ProductLabels.vue"
  );
const Categories = () =>
  import(
    /* webpackChunkName: "categories" */ "./views/catalogue/Categories.vue"
  );
const Options = () =>
  import(/* webpackChunkName: "options" */ "./views/catalogue/Options.vue");
const Users = () => import("./views/Users.vue");
const Settings = () => import("./views/settings/Settings.vue");
const AllOrders = () => import("./views/orders/AllOrders.vue");
const UnpaidOrders = () => import("./views/orders/UnpaidOrders.vue");
const PaidOrders = () => import("./views/orders/PaidOrders.vue");
const ActiveOrders = () => import("./views/orders/ActiveOrders.vue");
const SingleOrder = () =>
  import(
    /* webpackChunkName: "single-order" */ "./views/orders/SingleOrder.vue"
  );
const OrderPickingSheet = () =>
  import(
    /* webpackChunkName: "single-order" */ "./views/orders/OrderPickingSheet.vue"
  );
const SearchOrder = () =>
  import(
    /* webpackChunkName: "search-order" */ "./views/orders/SearchOrder.vue"
  );
const TransactionHistory = () =>
  import("./views/payments/TransactionHistory.vue");
const DownloadPaymentStatements = () =>
  import("./views/payments/DownloadStatements.vue");
const DownloadOrdersStatements = () =>
  import("./views/orders/DownloadStatements.vue");
const Refunds = () =>
  import(/* webpackChunkName: "refunds" */ "./views/payments/Refunds.vue");
const Cashout = () =>
  import(/* webpackChunkName: "cashout" */ "./views/payments/Cashout.vue");
const ShopQRCode = () =>
  import(/* webpackChunkName: "shop-qr-code" */ "./views/ShopQRCode.vue");

const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "./views/dashboard/Dashboard.vue");
const DashboardDeepDive = () =>
  import(
    /* webpackChunkName: "dashboard" */ "./views/dashboard/DashboardDeepDive.vue"
  );

const OrderWaybill = () => import("./views/OrderQRCode.vue");

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: Dashboard,
      props: true,
      beforeEnter: (to, from, next) => {
        importOrdersState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Dashboard",
      },
    },
    {
      path: "/dashboard/deep-dive",
      name: "dashboard-deep-dive",
      component: DashboardDeepDive,
      props: true,
      beforeEnter: (to, from, next) => {
        setPageTitle(to);
        next();
      },
      meta: {
        title: "Dashboard Deep Dive",
      },
    },

    {
      path: "/",
      name: "login",
    },
    {
      path: "/shop-qr-code",
      name: "shop-qr-code",
      component: ShopQRCode,
      beforeEnter: (to, from, next) => {
        setPageTitle(to);
        next();
      },
      meta: {
        title: "Shop QR Code",
      },
    },
    {
      path: "/catalogue/products",
      name: "products",
      component: Products,
      beforeEnter: (to, from, next) => {
        importProductsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Products",
      },
    },
    {
      path: "/catalogue/product-labels",
      name: "productLabels",
      component: ProductLabels,
      beforeEnter: (to, from, next) => {
        importProductLabelsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Product Labels",
      },
    },
    {
      path: "/catalogue/categories",
      name: "categories",
      component: Categories,
      beforeEnter: (to, from, next) => {
        importCategoriesState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Categories",
      },
    },
    {
      path: "/catalogue/options",
      name: "options",
      component: Options,
      beforeEnter: (to, from, next) => {
        importOptionsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Options",
      },
    },
    {
      path: "/catalogue/product-create",
      name: "product-create",
      component: ProductCreate,
      beforeEnter: (to, from, next) => {
        importProductsState().then(() => {});
        importOptionsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Product Create",
      },
    },
    {
      path: "/catalogue/products/:productId/edit",
      name: "product-edit",
      props: true,
      component: ProductEdit,
      beforeEnter: (to, from, next) => {
        importProductsState().then(() => {});
        importOptionsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Product Edit",
      },
    },
    {
      path: "/payments/accounts",
      name: "accounts",
      component: Accounts,
      beforeEnter: (to, from, next) => {
        setPageTitle(to);
        next();
      },
      meta: {
        title: "Accounts",
      },
    },
    {
      path: "/staff",
      name: "users",
      component: Users,
      beforeEnter: (to, from, next) => {
        importUsersState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Staff",
      },
    },
    {
      path: "/settings/:activeTab?",
      name: "settings",
      props: true,
      component: Settings,
      beforeEnter: (to, from, next) => {
        setPageTitle(to);
        next();
      },
      meta: {
        title: "Settings",
      },
    },
    {
      path: "/address/create",
      name: "address-create",
      component: () =>
        import(
          /* webpackChunkName: "address-create" */ "./views/settings/AddressCreate.vue"
        ),
      beforeEnter: (to, from, next) => {
        importAddressState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Address Create",
      },
    },
    {
      path: "/address/edit/:addressId",
      name: "address-edit",
      component: () =>
        import(
          /* webpackChunkName: "address-create" */ "./views/settings/AddressCreate.vue"
        ),
      beforeEnter: (to, from, next) => {
        importAddressState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Address Edit",
      },
    },
    {
      path: "/orders/all",
      name: "all-orders",
      component: AllOrders,
      beforeEnter: (to, from, next) => {
        importOrdersState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Order History",
      },
    },
    {
      path: "/orders/active",
      name: "active-orders",
      component: ActiveOrders,
      props: true,
      beforeEnter: (to, from, next) => {
        importOrdersState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Active Orders",
      },
    },
    {
      path: "/orders/unpaid",
      name: "unpaid-orders",
      component: UnpaidOrders,
      props: true,
      beforeEnter: (to, from, next) => {
        importOrdersState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Unpaid Orders",
      },
    },
    {
      path: "/orders/paid",
      name: "paid-orders",
      component: PaidOrders,
      props: true,
      beforeEnter: (to, from, next) => {
        importOrdersState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Paid Orders",
      },
    },
    {
      path: "/orders/:orderId/view",
      name: "view-order",
      component: SingleOrder,
      props: true,
      meta: {
        title: "Order",
        breadCrumbs: {
          possiblePatterns: [
            { to: "/orders/active", text: "Active Orders", disabled: false },
            { to: "/orders/all", text: "Order History", disabled: false },
          ],
        },
      },
      beforeEnter: async (to, from, next) => {
        setPageTitle(to);
        buildBreadCrumbs(to, from, "Order View");
        await importOrdersState();
        next();
      },
    },
    {
      path: "/orders/:orderId/picking-sheet",
      name: "view-order-picking-sheet",
      component: OrderPickingSheet,
      props: true,
      meta: {
        title: "Order Picking Sheet",
        breadCrumbs: {
          possiblePatterns: [
            { to: "/orders/active", text: "Active Orders", disabled: false },
            { to: "/orders/all", text: "Order History", disabled: false },
          ],
        },
      },
      beforeEnter: async (to, from, next) => {
        setPageTitle(to);
        buildBreadCrumbs(to, from, "Picking Sheet");
        await importOrdersState();
        next();
      },
    },
    {
      path: "/orders/:orderId/waybill",
      name: "view-order-waybill",
      component: OrderWaybill,
      props: true,
      beforeEnter: (to, from, next) => {
        importOrdersState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Order Waybill",
      },
    },
    {
      path: "/orders/search",
      name: "search-order",
      component: SearchOrder,
      props: true,
      beforeEnter: async (to, from, next) => {
        await importOrdersState();
        setPageTitle(to);
        next();
      },
      meta: {
        title: "Order Search",
      },
    },
    {
      path: "/customers",
      name: "my-customers",
      component: MyCustomers,
      beforeEnter: async (to, from, next) => {
        await importConnectionsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "My Customers",
      },
    },
    {
      path: "/customers/history",
      name: "customer-order-history",
      component: CustomerOrderHistory,
      beforeEnter: async (to, from, next) => {
        await importConnectionsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Customers Order History",
      },
    },
    {
      path: "/customers/pending",
      name: "pending-customers",
      component: PendingCustomers,
      beforeEnter: async (to, from, next) => {
        await importConnectionsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Customers Pending",
      },
    },
    {
      path: "/customers/possible",
      name: "possible-connections",
      component: PossibleConnections,
      beforeEnter: async (to, from, next) => {
        await importConnectionsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Possible Customers",
      },
    },
    {
      path: "/customers/:connectionId/discount",
      name: "customer-discount",
      component: CustomerDiscount,
      props: true,
      beforeEnter: async (to, from, next) => {
        await importConnectionsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Customer Discount",
      },
    },
    {
      path: "/customers/:connectionId/settings",
      name: "customer-settings",
      component: CustomerSettings,
      props: true,
      beforeEnter: async (to, from, next) => {
        await importConnectionsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Customer Settings",
      },
    },
    {
      path: "/customers/:connectionId/overview",
      name: "my-customer-overview",
      component: CustomerOverview,
      props: true,
      beforeEnter: async (to, from, next) => {
        await importConnectionsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Customer Overview",
      },
    },
    {
      path: "/customers/:connectionId/order",
      name: "customer-order",
      component: CustomerOrder,
      props: true,
      beforeEnter: async (to, from, next) => {
        await importConnectionsState().then(() => {
          setPageTitle(to);
          importProductsState().then(() => {
            next();
          });
        });
      },
      meta: {
        title: "Customer Order",
      },
    },
    {
      path: "/payments/transaction-history",
      name: "transaction-history",
      component: TransactionHistory,
      beforeEnter: (to, from, next) => {
        importTransactionsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Transaction History",
      },
    },
    {
      path: "/payments/refunds",
      name: "refunds",
      component: Refunds,
      beforeEnter: (to, from, next) => {
        importTransactionsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Refunds",
      },
    },
    {
      path: "/payments/cashout",
      name: "cashout",
      component: Cashout,
      beforeEnter: (to, from, next) => {
        importTransactionsState().then(() => {
          setPageTitle(to);
          next();
        });
      },
      meta: {
        title: "Cashout",
      },
    },
    {
      path: "/payments/statements",
      name: "statements",
      component: DownloadPaymentStatements,
      beforeEnter: (to, from, next) => {
        setPageTitle(to);
        next();
      },
      meta: {
        title: "Payment Statements",
        type: "payments",
      },
    },
    {
      path: "/orders/statements",
      name: "statements-order",
      component: DownloadOrdersStatements,
      beforeEnter: (to, from, next) => {
        setPageTitle(to);
        next();
      },
      meta: { title: "Orders Statements", type: "orders" },
    },
  ],
});

/**
 * This is a very simple breadcrumps builder. It only supports one level deep for now.
 * This will most like evolve over time.
 *
 * In each route which you want to display a breadcrumb you need to add the following
 * under the meta attribute. Each possible pattern is the previous page's route information.
 * Possible pattern is an array because some pages may be accessed from different paths.
 *
 * breadCrumbs:
 * {
 *		possiblePatterns: [
 *			{ to: "/orders/active", text: "Active Orders", disabled: false },
 *			{ to: "/orders/all", text: "Order History", disabled: false }
 *		]
 *	}
 */
const buildBreadCrumbs = (to: Route, from: Route, text: string) => {
  if (!to.meta!.breadCrumbs || !from.name) return;
  for (let pattern of to.meta!.breadCrumbs.possiblePatterns) {
    if (pattern.to === from.path) {
      to.meta!.breadCrumbs.pattern = [
        pattern,
        { to: to.path, text, disabled: true },
      ];
      break;
    }
  }
};

const setPageTitle = (to: Route) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title + " | Supplier Dash";
  } else {
    document.title = "Supplier Dash";
  }
};
