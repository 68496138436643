import { Module } from "vuex";
import { getters } from "@/store/shops/getters";
import { actions } from "@/store/shops/actions";
import { mutations } from "@/store/shops/mutations";
import { RootState } from "@/store/types";
import { ShopsState, Settings } from "@/store/shops/types";

export const state: ShopsState = {
  activeShop: undefined!,
  settings: {} as Settings,
  fees: {},
  notifications: [],
  availableNotificationSubscriptions: [],
  defaultNotificationSubscriptions: [],
  notificationSubscription: [],
  availablePushySubscriptions: [],
  defaultPushySubscriptions: [],
  activeShopToken: undefined!,
  myShops: undefined!,
  searchedOrganisations: [],
  loading: false,
  switchShop: false,
};

const namespaced: boolean = true;

export const shops: Module<ShopsState, RootState> = {
  namespaced,
  state: state,
  getters,
  actions,
  mutations,
};
