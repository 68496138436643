import { Module } from "vuex";
import { getters } from "@/store/accounts/getters";
import { actions } from "@/store/accounts/actions";
import { mutations } from "@/store/accounts/mutations";
import { AccountsState } from "@/store/accounts/types";
import { RootState } from "@/store/types";

export const state: AccountsState = {
  accounts: [],
  selectedAccount: undefined,
  balance: "0",
  transferDepositAccounts: [],
  loading: false,
  balanceLoading: false,
};

const namespaced: boolean = true;

export const accounts: Module<AccountsState, RootState> = {
  namespaced,
  state: state,
  getters,
  actions,
  mutations,
};
