import { Module } from "vuex";
import { getters } from "@/store/auth/getters";
import { actions } from "@/store/auth/actions";
import { mutations } from "@/store/auth/mutations";
import { AuthState, User } from "@/store/auth/types";
import { RootState } from "@/store/types";

export const state: AuthState = {
  token: "",
  isAuthenticated: false,
  loggedInUser: undefined,
};

const namespaced: boolean = true;

export const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
