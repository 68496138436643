import { Paging } from "@/store/utils/";
import { Transaction } from "../transactions/types";
import { Address } from "../modules/addresses";

export type orderStatusObj = { name: orderStatus; actionable: boolean };
export type orderStatus =
  | "New"
  | "Pending"
  | "Accepted"
  | "Ready"
  | "Collected"
  | "Delivered"
  | "Completed"
  | "Cancelled"
  | "Rejected"
  | "In Transit";

// TODO: We need to add the order status color here as well
export function getOrderStatusName(
  orderStatusId: number,
  actionable?: boolean
): orderStatusObj | boolean {
  let obj: orderStatusObj;
  switch (orderStatusId) {
    case 1:
      obj = { name: "New", actionable: true };
      break;
    case 2:
      obj = { name: "Pending", actionable: true };
      break;
    case 3:
      obj = { name: "Accepted", actionable: true };
      break;
    case 4:
      obj = { name: "Cancelled", actionable: false };
      break;
    case 5:
      obj = { name: "Ready", actionable: true };
      break;
    case 6:
      obj = { name: "Collected", actionable: false };
      break;
    case 7:
      obj = { name: "Delivered", actionable: false };
      break;
    case 8:
      obj = { name: "Completed", actionable: false };
      break;
    case 9:
      obj = { name: "Rejected", actionable: false };
      break;
    case 12:
      obj = { name: "In Transit", actionable: false };
      break;
    default:
      throw Error(`Order status(${orderStatusId}) not recognised!`);
  }

  if (actionable) return obj.actionable;
  else return obj;
}

export function isOrderActionable(orderStatusId: number): boolean {
  const actionable = getOrderStatusName(orderStatusId, true);
  return typeof actionable === "boolean" ? actionable : false;
}

export interface OrderStatus {
  id: number;
  name: string;
  description: string;
}

export interface Order {
  id: number;
  grn: string;
  deliveryType: "DELIVERY" | "COLLECTION";
  shortReference: string;
  dateCreated: Date;
  status: OrderStatus;
  paymentStatus: "PAID" | "UNPAID";
  currentPaymentStatus?: "PAID" | "UNPAID";
  organisation: { id: number };
  items: { detail: { organisation: { id: number } } }[];
}

export interface OrderByStatus {
  [orderStatusId: number]: {
    orders: Order[];
    pagination?: Paging;
  };
}

export interface Filters {
  sort?: "date+" | "date-";
  fromDate?: string;
  toDate?: string;
  orderstatus?: string[] | number[];
  paymentstatus?: string[];
  orgsearch?: string;
  salesOrder?: boolean;
}

export interface Segment {
  sum: number;
  count: number;
  description: string;
}

export interface DashboardData {
  [group: string]: {
    sum: number;
    count: number;
    segments: Array<Segment>;
  };
}

export interface ParcelData {
  boxSizeId: number;
  count: number;
}

export interface BoxSizes {
  id: number;
  name: string;
  description: string;
}

export interface Provider {
  id: number;
  name?: string;
}

export interface Providers {
  fee?: number;
  warehouseId?: string;
  provider?: Provider;
}

export interface DeliveryPlace {
  country: "string";
  province: "string";
  city?: "string";
  suburb?: "string";
  street?: "string";
}

export interface DeliveryRestrictions {
  distance: number;
  places?: Array<DeliveryPlace>;
}

export interface OrdersState {
  orders: Order[];
  selectedOrder: Order;
  orderAddress: Address;
  orderTransactions?: Transaction[];
  activeOrderIds: number[];
  ordersByStatus: OrderByStatus;
  orderStatusses: OrderStatus[];
  orderStatusOverview: any;
  providers: Array<Providers>;
  deliverySettings: Providers;
  deliveryRestrictions: DeliveryRestrictions;
  boxSizes: Array<BoxSizes>;
  allowedBoxSizes: Array<BoxSizes>;
  dashboardData: DashboardData;
  pagination: Paging;
  totalItems: number;
  filters: Filters;
  loading: boolean;
}
