import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import keycloak from "keycloak-js";
import { AuthState } from "./types";

export const actions: ActionTree<AuthState, RootState> = {
  async authRequest({ commit }) {
    const kc = keycloak({
      url: process.env.VUE_APP_AUTH_SERVER_URL,
      realm: process.env.VUE_APP_REALM!,
      clientId: process.env.VUE_APP_RESOURCE!,
    });

    const initOptions = {
      responseMode: <keycloak.KeycloakResponseMode>"fragment",
      flow: <keycloak.KeycloakFlow>"standard",
      onLoad: <keycloak.KeycloakOnLoad>"login-required",
      checkLoginIframe: false,
    };

    const kcinit = () =>
      kc
        .init(initOptions)
        .success((authenticated: boolean) => {
          if (authenticated) {
            kc.loadUserInfo().success((user) => {
              commit("setLoggedInUser", user);
            });

            // Here we remove that ugly keycloak hash
            window.location.hash = "";
            window.history.pushState(
              null,
              "",
              window.location.href.replace("#", "")
            );

            commit("setAuthenticated", authenticated);
            commit("setToken", kc.token);
            commit("setLogoutFunction", kc.logout);
            commit("setUpdateTokenFunction", updateToken);
          }
        })
        .error((error) => {
          /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
          console.error("error", error);
        });

    kcinit();

    const updateToken = async () =>
      kc
        .updateToken(20)
        .success((refreshed: boolean) => {
          if (refreshed) {
            commit("setToken", kc.token);
          }
        })
        .error(function () {
          kcinit();
        });
  },

  updateToken({ state }) {
    if (state.updateToken) state.updateToken();
  },

  logout({ state }) {
    if (state.logout) state.logout();
  },
};
