import Axios from "axios";
import { NotificationConfig, Settings, Shop } from "@/store/shops/types";
import { store } from "@/store";
import { generateUrl, generateHeader } from "./http-common";

export interface AddUserToShopObject {
  organisationId: number;
  groupId: number;
  userId: string;
}

export interface UpdateOrganisationContactObject {
  contactPerson: {
    id: string;
    profile: {
      firstname: string;
      surname: string;
      email: string;
      nickname: string;
      cellphoneNumber: string;
      sex: string;
      image: string;
      imageThumbnail: string;
    };
  };
  contactNumber?: string;
  notificationSourceEmail?: string;
}

export async function getOrganisationsBySearch(searchVal: string) {
  return Axios.get(generateUrl(`organisations/search`), {
    params: { name: searchVal },
    headers: await generateHeader(),
  });
}

export async function fetchOrganisation(orgId: number) {
  return Axios.get(generateUrl(`organisations/${orgId}`), {
    headers: await generateHeader(true),
  });
}

export async function fetchMyShops(orgName: string) {
  return Axios.get(
    generateUrl(
      `authorization/user/organisations?pagesize=50&search=${orgName}`
    ),
    {
      headers: await generateHeader(true),
    }
  );
}

export async function fetchSettings() {
  return Axios.get(
    generateUrl(`organisations/${store.state.shops.activeShop.id}/settings`),
    {
      headers: await generateHeader(),
    }
  );
}

export async function fetchDefaultNotificationSubscriptions() {
  return Axios.get(generateUrl(`notifications/default/email`), {
    headers: await generateHeader(),
  });
}

export async function fetchAvailableNotificationSubscriptions() {
  return Axios.get(generateUrl(`notifications/available/email`), {
    headers: await generateHeader(),
  });
}

export async function fetchAvailablePushySubscriptions() {
  return Axios.get(generateUrl(`notifications/available/pushy`), {
    headers: await generateHeader(),
  });
}

export async function fetchNotificationsSubscriptions(email: string) {
  const endpoint =
    email == "" || email == null
      ? `notifications/organisation/${store.state.shops.activeShop.id}/email`
      : `notifications/organisation/${store.state.shops.activeShop.id}/email/${email}`;
  return Axios.get(generateUrl(endpoint), {
    headers: await generateHeader(),
  });
}

export async function fetchDefaultPushySubscriptions() {
  return Axios.get(
    generateUrl(
      `notifications/organisation/${store.state.shops.activeShop.id}/pushy`
    ),
    {
      headers: await generateHeader(),
    }
  );
}

export async function updateNotifierSettings(
  email: string,
  notifications: any
) {
  return Axios.post(
    generateUrl(
      `notifications/organisation/${store.state.shops.activeShop.id}/email/${email}`
    ),
    { ...notifications },
    {
      headers: await generateHeader(),
    }
  );
}

export async function updatePushySettings(config: any) {
  return Axios.post(
    generateUrl(
      `notifications/organisation/${store.state.shops.activeShop.id}/pushy`
    ),
    { config: config },
    {
      headers: await generateHeader(),
    }
  );
}

export async function removeNotifier(email: string) {
  return Axios.delete(
    generateUrl(
      `notifications/organisation/${store.state.shops.activeShop.id}/email/${email}`
    ),
    {
      headers: await generateHeader(),
    }
  );
}

export async function updateNotifications(notifications: any) {
  return Axios.put(
    generateUrl(`organisations/${store.state.shops.activeShop.id}/settings`),
    { ...notifications },
    {
      headers: await generateHeader(),
    }
  );
}

export async function updateSettings(settingsObj: Settings) {
  return Axios.put(
    generateUrl(`organisations/${store.state.shops.activeShop.id}`),
    { ...settingsObj },
    {
      headers: await generateHeader(),
    }
  );
}

export async function uploadImage(formData: FormData) {
  return Axios.put(
    generateUrl(`organisations/${store.state.shops.activeShop.id}/image`),
    formData,
    {
      headers: {
        ...(await generateHeader()),
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export async function updateOrgansation(organisation: Shop) {
  return Axios.put(
    generateUrl(`organisations/${store.state.shops.activeShop.id}`),
    organisation,
    {
      headers: await generateHeader(true),
    }
  );
}

export async function fetchShopToken(shopId: number) {
  return Axios.get(generateUrl(ShopsUrls.fetchShopTokenUrl(shopId)), {
    headers: await generateHeader(true),
  });
}

export async function addUserToOrganisation(
  addUserToOrgObj: AddUserToShopObject
) {
  return Axios.post(
    generateUrl(
      `authorization/organisation/${addUserToOrgObj.organisationId}/group/${addUserToOrgObj.groupId}/members`
    ),
    [addUserToOrgObj.userId],
    {
      headers: await generateHeader(),
    }
  );
}

export async function removeGroupFromUser(
  addUserToOrgObj: AddUserToShopObject
) {
  return Axios.delete(
    generateUrl(
      `authorization/organisation/${addUserToOrgObj.organisationId}/group/${addUserToOrgObj.groupId}/members`
    ),
    {
      headers: await generateHeader(),
      data: [addUserToOrgObj.userId],
    }
  );
}

export async function updateOrganisationContact(
  organisationId: number,
  addUserToOrgObj: UpdateOrganisationContactObject
) {
  return Axios.put(
    generateUrl(`organisations/${organisationId}/detail`),
    { ...addUserToOrgObj },
    {
      headers: await generateHeader(),
    }
  );
}

//GET /organisations/{organisationId}/accesstoken

namespace ShopsUrls {
  export const fetchShopTokenUrl = (orgId: number) => {
    return `organisations/${orgId}/accesstoken`;
  };
}
