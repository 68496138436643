import { MutationTree } from "vuex";
import { AccountsState } from "@/store/accounts/types";

export const mutations: MutationTree<AccountsState> = {
  setAccounts(state, payload) {
    state.accounts = payload;
    state.loading = false;
  },

  setBalance(state, payload) {
    state.balance = payload;
    state.balanceLoading = false;
  },

  setSelectedAccount(state, payload) {
    state.selectedAccount = payload;
    state.loading = false;
  },

  setTransferDepositAccounts(state, payload) {
    state.transferDepositAccounts = payload;
  },

  balanceLoading(state) {
    state.balanceLoading = true;
  },

  loading(state, payload = true) {
    state.loading = payload;
  },
};
