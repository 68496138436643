import { store } from "@/store";
import { Account } from "@/store/accounts/types";
import Axios from "axios";
import { generateUrl, generateHeader } from "./http-common";

export namespace Accounts {
  export async function getAccounts(tx_type?: string, kind?: string) {
    return Axios.get(
      generateUrl(`organisations/${store.state.shops.activeShop.id}/accounts`),
      {
        params: {
          tx_type,
          kind,
        },
        headers: await generateHeader(),
      }
    );
  }

  export async function updateAccountName(accountId: number, account: Account) {
    return Axios.put(
      generateUrl(`accounts/${accountId}`),
      {
        ...account,
      },
      { headers: await generateHeader() }
    );
  }

  export async function fetchAccount(accountId: number) {
    return Axios.get(generateUrl(`accounts/${accountId}`), {
      headers: await generateHeader(),
    });
  }

  export async function fetchAccountBalance() {
    return Axios.get(
      generateUrl(
        `v2/accounts/${store.state.shops.activeShop.code}/balance-summary`
      ),
      {
        headers: await generateHeader(),
      }
    );
    /* return Axios.get(generateUrl(`accounts/${accountId}`), {
      headers: await generateHeader(),
    }); */
  }

  export async function createBankAccount(body: any) {
    return Axios.post(
      generateUrl(`organisations/${store.state.shops.activeShop.id}/accounts`),
      {
        ...body,
      },
      { headers: await generateHeader() }
    );
  }
}
