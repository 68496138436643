export interface Status {
  loading?: boolean;
  success?: boolean | string;
  error?: boolean | string;
  refresh?: boolean | string;
}

export interface Paging {
  page: number;
  itemsPerPage: number;
  sortBy: [];
  descending: boolean;
  totalItems?: number;
  filters: { [key: string]: any };
}

export function parseSort(paging: Paging) {
  if (paging.sortBy && paging.sortBy.length > 0)
    return `${paging.sortBy}.${paging.descending ? "desc" : "asc"}`;
  else return undefined;
}

export const pagingDefault: Paging = {
  page: 1,
  itemsPerPage: 25,
  sortBy: [],
  totalItems: 0,
  descending: false,
  filters: {} as { [key: string]: any },
};
