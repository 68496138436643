import { store } from "@/store";

export function generateUrl(uri: string, hostUrlOverride?: string): string {
  if (hostUrlOverride) return hostUrlOverride + uri;
  return `${process.env.VUE_APP_API_HOST_URL}resource/` + uri;
}

export function generateUrlWS(uri: string): string {
  return `${process.env.VUE_APP_API_WS_URL}` + uri;
}

export function generateKeycloakUrl(uri: string): string {
  return (
    `${process.env.VUE_APP_AUTH_SERVER_URL}/admin/realms/${process.env.VUE_APP_REALM}/` +
    uri
  );
}

export async function generateKeycloakHeader() {
  if (store.state.auth.updateToken) await store.state.auth.updateToken();
  const myHeaders: any = {};
  myHeaders["Authorization"] = "Bearer " + store.state.auth.token;
  return myHeaders;
}

export async function generateHeader(useMarketPlaceHeader = false) {
  if (store.state.auth.updateToken) await store.state.auth.updateToken();
  const myHeaders: any = {};

  //check if the call is a marketplace connection
  let client = store.state.shops.activeShopToken;

  if (useMarketPlaceHeader && process.env.VUE_APP_CLIENT_ID) {
    client = process.env.VUE_APP_CLIENT_ID;
  }

  myHeaders["Authorization"] = "Bearer " + store.state.auth.token;
  myHeaders["X-Ordercloud-Organisation"] = client;

  return myHeaders;
}
