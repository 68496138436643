import { store } from "@/store";
import Axios from "axios";
import { generateUrl, generateHeader } from "./http-common";
import { parseSort } from "@/store/utils";

export interface transactionsFetchParams {
  [key: string]: number | string | unknown;
  account_id?: number;
  order_id?: number;
}

export interface RefundBody {
  transactingOrgId: number;
  txnType: string;
  from: {
    account: {
      amount: number;
    };
  };
  to: {
    organisation: {
      id: number;
    };
  };
  sourceReference: string;
  targetReference: string;
}

export interface accountInfo {
  account: {
    id: number;
    amount?: number;
  };
}

export interface CashoutBody {
  txnType: string;
  transactingOrgId: number;
  from: accountInfo;
  to: accountInfo;
  transactorReference?: string;
  sourceReference?: string;
}

export async function fetchTransaction(
  paging: any,
  otherParams: transactionsFetchParams
) {
  return Axios.get(generateUrl(`v2/transactions/history`), {
    params: {
      org_id: store.state.shops.activeShop.id,
      page_number: paging.page,
      page_size: paging.itemsPerPage,
      sort: parseSort(paging),
      ...otherParams,
    },
    headers: await generateHeader(),
  });
}

export async function getOrganisationByCode(code: string) {
  return Axios.get(generateUrl(`organisations/code/${code}`), {
    headers: await generateHeader(),
  });
}

export async function refundTransaction(data: RefundBody) {
  return Axios.post(
    generateUrl(`v2/transactions`),
    {
      ...data,
    },
    { headers: await generateHeader() }
  );
}

export async function refundTransactionExecute(data: any) {
  return Axios.put(
    generateUrl(`v2/transactions/start`),
    {
      ...data,
    },
    { headers: await generateHeader() }
  );
}

export async function cashoutTransaction(data: CashoutBody) {
  return Axios.post(
    generateUrl(`v2/transactions`),
    {
      ...data,
    },
    { headers: await generateHeader() }
  );
}

export async function cashoutTransactionExecute(data: any) {
  return Axios.put(
    generateUrl(`v2/transactions/start`),
    {
      ...data,
    },
    { headers: await generateHeader() }
  );
}
