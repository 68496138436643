import { ActionTree } from "vuex";
import {
  ShopsState,
  Shop,
  NotificationSubscription,
  NotificationConfig,
} from "@/store/shops/types";
import { RootState } from "@/store/types";
import {
  fetchMyShops,
  getOrganisationsBySearch,
  fetchOrganisation,
  fetchShopToken,
  updateSettings,
  uploadImage,
  addUserToOrganisation,
  updateOrganisationContact,
  UpdateOrganisationContactObject,
  removeGroupFromUser,
  fetchSettings,
  fetchDefaultNotificationSubscriptions,
  fetchAvailableNotificationSubscriptions,
  fetchNotificationsSubscriptions,
  updateNotifications,
  updateOrgansation,
  updateNotifierSettings,
  updatePushySettings,
  removeNotifier,
  fetchDefaultPushySubscriptions,
  fetchAvailablePushySubscriptions,
} from "@/api/shops";
import { User } from "../users/types";

export const actions: ActionTree<ShopsState, RootState> = {
  switchShop({ commit }) {
    commit("switchShop");
  },

  async fetchMyShops({ commit }, orgName: string) {
    try {
      commit("loading");
      const result = await fetchMyShops(orgName);
      commit("setMyShops", result.data.results);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async fetchFees({ commit }) {
    try {
      commit("loading");
      const result = await fetchSettings();
      commit("setFees", result.data.fees);
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async fetchNotifications({ commit }) {
    try {
      commit("loading");
      const result = await fetchSettings();
      commit("setNotifications", result.data.transactions.notification);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async fetchDefaultNotificationSubscriptions({ commit }) {
    try {
      commit("loading");
      const result = await fetchDefaultNotificationSubscriptions();
      commit("setDefaultNotificationSettings", result.data);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async fetchAvailableNotificationSubscriptions({ commit }) {
    try {
      commit("loading");
      const result = await fetchAvailableNotificationSubscriptions();

      const item_order: any = {
        "Buyer/Seller": 1,
        Buyer: 2,
        Seller: 3,
        "Supplier/Customer": 1,
        Supplier: 2,
        Customer: 3,
        "Debit/Credit": 1,
        Debit: 2,
        Credit: 3,
      };

      let arrayObj: any = {};
      result.data.forEach((item: any) => {
        let eventTypeId = item.eventTypeId.split(".")[0];
        let partyName = item.partyTypeName.replace(/[\s]/g, "-");

        if (!arrayObj[eventTypeId]) {
          // new
          arrayObj[eventTypeId] = {};
          if (
            !arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`]
          ) {
            arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`] =
              [];
            arrayObj[eventTypeId][
              `${item_order[partyName]}. ${partyName}`
            ].push(item);
          } else {
            arrayObj[eventTypeId][
              `${item_order[partyName]}. ${partyName}`
            ].push(item);
          }
        } else {
          // exists
          if (
            !arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`]
          ) {
            arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`] =
              [];
            arrayObj[eventTypeId][
              `${item_order[partyName]}. ${partyName}`
            ].push(item);
          } else {
            arrayObj[eventTypeId][
              `${item_order[partyName]}. ${partyName}`
            ].push(item);
          }
        }
      });

      // sort object
      for (const [key, value] of Object.entries(arrayObj)) {
        arrayObj[key] = Object.fromEntries(
          Object.entries(arrayObj[key]).sort()
        );
      }

      commit("setAvailableNotificationSettings", arrayObj);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async fetchNotificationsSubscriptions({ commit }, email: string) {
    try {
      commit("loading");
      const result = await fetchNotificationsSubscriptions(email);
      commit("setNotificationSettings", result.data);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async fetchAvailablePushySubscriptions({ commit, dispatch }) {
    try {
      commit("loading");
      const result = await fetchAvailablePushySubscriptions();
      const item_order: any = {
        "Buyer/Seller": 1,
        Buyer: 2,
        Seller: 3,
        "Supplier/Customer": 1,
        Supplier: 2,
        Customer: 3,
        "Debit/Credit": 1,
        Debit: 2,
        Credit: 3,
      };

      let arrayObj: any = {};
      result.data.forEach((item: any) => {
        let eventTypeId = item.eventTypeId.split(".")[0];
        let partyName = item.partyTypeName.replace(/[\s]/g, "-");

        if (!arrayObj[eventTypeId]) {
          // new
          arrayObj[eventTypeId] = {};
          if (
            !arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`]
          ) {
            arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`] =
              [];
            arrayObj[eventTypeId][
              `${item_order[partyName]}. ${partyName}`
            ].push(item);
          } else {
            arrayObj[eventTypeId][
              `${item_order[partyName]}. ${partyName}`
            ].push(item);
          }
        } else {
          // exists
          if (
            !arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`]
          ) {
            arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`] =
              [];
            arrayObj[eventTypeId][
              `${item_order[partyName]}. ${partyName}`
            ].push(item);
          } else {
            arrayObj[eventTypeId][
              `${item_order[partyName]}. ${partyName}`
            ].push(item);
          }
        }
      });

      // sort object
      for (const [key, value] of Object.entries(arrayObj)) {
        arrayObj[key] = Object.fromEntries(
          Object.entries(arrayObj[key]).sort()
        );
      }

      commit("setAvailablePushySettings", arrayObj);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async fetchDefaultPushySubscriptions({ commit, dispatch }) {
    try {
      commit("loading");
      const result = await fetchDefaultPushySubscriptions();
      commit("setDefaultPushySettings", result.data.config);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
    }
  },

  async updateNotifierSettings(
    { commit, dispatch },
    settings: NotificationSubscription
  ) {
    commit("loading");

    const newConfig = Object.entries(settings.config).map((item: any) => {
      return {
        eventTypeId: item[1].eventTypeId,
        partyTypeId: item[1].partyTypeId,
        statusId: item[1].statusId,
      };
    });

    let notifications = { person: settings.person, config: newConfig };
    const result = await updateNotifierSettings(settings.email, notifications);
    if (result.status === 200) {
      dispatch("fetchNotificationsSubscriptions");
    } else {
      dispatch("notifications/error", "Update failed, please try again.", {
        root: true,
      });
    }
  },

  async updatePushySettings(
    { commit, dispatch },
    config: Array<NotificationConfig>
  ) {
    commit("loading");

    const newConfig = Object.entries(config).map((item: any) => {
      return {
        eventTypeId: item[1].eventTypeId,
        partyTypeId: item[1].partyTypeId,
        statusId: item[1].statusId,
      };
    });

    const result = await updatePushySettings(newConfig);
    if (result.status === 200) {
      dispatch("fetchNotificationsSubscriptions");
    } else {
      dispatch("notifications/error", "Update failed, please try again.", {
        root: true,
      });
    }
  },

  async removeNotifier({ commit, dispatch }, email: string) {
    commit("loading");

    await removeNotifier(email);
    commit("notifications/success", "Notifier removed", { root: true });
    dispatch("fetchNotificationsSubscriptions");
  },

  async updateOrgName({ commit, state }, name) {
    try {
      commit("loading");
      const saveObj = JSON.parse(JSON.stringify(state.activeShop));
      saveObj.name = name;
      const typeIds = [];
      for (let type of saveObj.types) {
        typeIds.push(type.id);
      }
      saveObj.types = typeIds;
      delete saveObj.id;
      delete saveObj.code;
      delete saveObj.industries;
      delete saveObj.profile;
      delete saveObj.ordersHash;
      delete saveObj.status;
      delete saveObj.lastOnline;
      delete saveObj.delivering;
      delete saveObj.open;
      delete saveObj.anomalyMessage;
      delete saveObj.currency;

      await updateOrgansation(saveObj);
      commit("notifications/success", "Organisation Name Updated", {
        root: true,
      });
      const shopDetailed = await fetchOrganisation(state.activeShop.id);
      commit("setActiveShop", shopDetailed.data);
      commit("loading", false);
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async updateOrgBusinessDetails({ commit, state }) {
    try {
      commit("loading");
      const saveObj = JSON.parse(JSON.stringify(state.activeShop));
      const typeIds = [];
      for (let type of saveObj.types) {
        typeIds.push(type.id);
      }
      saveObj.types = typeIds;
      delete saveObj.id;
      delete saveObj.code;
      delete saveObj.industries;
      delete saveObj.profile;
      delete saveObj.ordersHash;
      delete saveObj.status;
      delete saveObj.lastOnline;
      delete saveObj.delivering;
      delete saveObj.open;
      delete saveObj.anomalyMessage;
      delete saveObj.currency;

      await updateOrgansation(saveObj);
      commit(
        "notifications/success",
        "Organisation Billing Information Updated",
        { root: true }
      );
      commit("loading", false);
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async updateNotifications({ commit, dispatch }, notifications) {
    try {
      commit("loading");
      notifications = { "transactions.notification": notifications };
      await updateNotifications(notifications);
      commit("notifications/success", "Notifications Updated", { root: true });
      dispatch("fetchNotifications");
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async updateSettings({ commit }, settings) {
    try {
      commit("loading");
      await updateSettings(settings);
      commit("notifications/success", "Settings Updated", { root: true });
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async uploadImage({ commit, dispatch }, formData) {
    try {
      commit("loading");
      await uploadImage(formData);
      commit("notifications/success", "New shop image uploaded", {
        root: true,
      });
      dispatch("refreshActiveShop");
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async searchOrganisations({ commit }, searchVal: string) {
    try {
      commit("loading");
      const result = await getOrganisationsBySearch(searchVal);
      commit("setSearchedOrganisations", result.data.results);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async setActiveShop({ commit }, activeShopId: number) {
    commit("loading");
    const shopDetailed = await fetchOrganisation(activeShopId);
    commit("setActiveShop", shopDetailed.data);

    commit("setSettings", {
      acceptPayments: shopDetailed.data.acceptPayments,
      acceptOrders: shopDetailed.data.acceptOrders,
      acceptCollections: shopDetailed.data.acceptCollections,
      acceptDeliveries: shopDetailed.data.acceptDeliveries,
      requirePayUpfront: shopDetailed.data.requirePayUpfront,
      acceptPayOnDelivery: shopDetailed.data.acceptPayOnDelivery,
    });

    localStorage.setItem("active-shop-id", String(activeShopId));

    const result = await fetchShopToken(activeShopId);
    commit("setActiveShopToken", result.data.token);
  },

  async refreshActiveShop({ commit, state }) {
    const shopDetailed = await fetchOrganisation(state.activeShop.id);
    commit("setActiveShop", shopDetailed.data);
  },

  async removeGroupFromUser(
    { commit, dispatch },
    addUserObject: { organisationId: number; user: User; groups: any }
  ) {
    try {
      commit("loading");
      const addUserApiObj = {
        organisationId: addUserObject.organisationId,
        userId: addUserObject.user.id,
      };
      if (!addUserObject.groups || addUserObject.groups.length < 1)
        addUserObject.groups = [200];

      if (addUserObject.groups.includes(200))
        await removeGroupFromUser({ groupId: 200, ...addUserApiObj });
      if (addUserObject.groups.includes(5))
        await removeGroupFromUser({ groupId: 5, ...addUserApiObj });
      if (addUserObject.groups.includes(2))
        await removeGroupFromUser({ groupId: 2, ...addUserApiObj });
      if (addUserObject.groups.includes(3))
        await removeGroupFromUser({ groupId: 3, ...addUserApiObj });

      dispatch("users/fetchUsers", null, { root: true });
      commit("notifications/success", `User Roles Updated.`, { root: true });
      commit("loading", false);
    } catch (error) {
      commit("notifications/error", error.message, { root: true });
    }
  },

  async addUserToOrganisation(
    { commit, dispatch },
    addUserObject: {
      organisationId: number;
      user: User;
      groups: any;
      successMessage?: string;
    }
  ) {
    try {
      commit("loading");
      const addUserApiObj = {
        organisationId: addUserObject.organisationId,
        userId: addUserObject.user.id,
      };
      if (!addUserObject.groups || addUserObject.groups.length < 1)
        addUserObject.groups = [200];

      if (addUserObject.groups.includes(200))
        await addUserToOrganisation({ groupId: 200, ...addUserApiObj });
      if (addUserObject.groups.includes(5))
        await addUserToOrganisation({ groupId: 5, ...addUserApiObj });
      if (addUserObject.groups.includes(2))
        await addUserToOrganisation({ groupId: 2, ...addUserApiObj });
      if (addUserObject.groups.includes(3))
        await addUserToOrganisation({ groupId: 3, ...addUserApiObj });

      dispatch("users/fetchUsers", null, { root: true });
      commit(
        "notifications/success",
        addUserObject.successMessage || `New user added!`,
        { root: true }
      );
      commit("loading", false);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async addPrimaryUserToOrganisation(
    { commit, rootState },
    addUserObject: { organisationId: number; user: User }
  ) {
    try {
      commit("loading");

      const organisationContactObj: UpdateOrganisationContactObject = {
        contactPerson: {
          id: addUserObject.user.id,
          profile: addUserObject.user.profile,
        },
      };
      await updateOrganisationContact(
        addUserObject.organisationId,
        organisationContactObj
      );

      const shopDetailed = await fetchOrganisation(
        rootState.shops.activeShop.id
      );
      commit("setActiveShop", shopDetailed.data);

      commit("notifications/success", "Primary User Update", { root: true });
      commit("loading", false);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  // This Will add user to organisation give him full right and make him primart
  async addUserToOrganisationWithGroups(
    { commit },
    addUserObject: { organisationId: number; user: User }
  ) {
    try {
      commit("loading");
      const addUserApiObj = {
        organisationId: addUserObject.organisationId,
        userId: addUserObject.user.id,
      };

      await addUserToOrganisation({ groupId: 200, ...addUserApiObj });
      await addUserToOrganisation({ groupId: 5, ...addUserApiObj });
      await addUserToOrganisation({ groupId: 2, ...addUserApiObj });
      await addUserToOrganisation({ groupId: 3, ...addUserApiObj });

      const organisationContactObj: UpdateOrganisationContactObject = {
        contactPerson: {
          id: addUserObject.user.id,
          profile: addUserObject.user.profile,
        },
      };
      await updateOrganisationContact(
        addUserObject.organisationId,
        organisationContactObj
      );

      commit("notifications/success", "Shop created and user assigned!", {
        root: true,
      });
      commit("loading", false);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  refresh({ dispatch }) {
    dispatch("fetchFees");
    dispatch("fetchNotifications");
  },
};
