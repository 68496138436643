import { ActionTree } from "vuex";
import { AccountsState, Account } from "@/store/accounts/types";
import { RootState } from "@/store/types";
import { Accounts } from "@/api/accounts";

export const actions: ActionTree<AccountsState, RootState> = {
  async getAccounts({ commit }) {
    try {
      commit("loading");
      const result = await Accounts.getAccounts();
      commit("setAccounts", result.data.results);
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async fetchBalance({ commit, dispatch }) {
    try {
      commit("balanceLoading");
      const result = await Accounts.fetchAccountBalance();
      dispatch("setBalance", result.data.currentBalance);
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async fetchAccount({ commit }, accountId) {
    try {
      commit("loading");
      const response = await Accounts.fetchAccount(accountId);
      commit("setSelectedAccount", response.data);
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async getTransferDepositAccounts({ commit }) {
    try {
      commit("loading");
      const result = await Accounts.getAccounts("transfer", "deposit");
      commit("setTransferDepositAccounts", result.data.results);
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },
  // For the Initial api call Balance is derived from an account, subsequent Websocket calls just passes through an balance String
  async setBalance({ commit }, value: string | Account[]) {
    if (typeof value === "string") commit("setBalance", value);
    else {
      for (let account of value) {
        if (account.methodKey === "system") {
          commit("setBalance", account.balance);
          break;
        }
      }
      if (value.length < 1) commit("setBalance", "0");
    }
  },

  async createAccount({ commit }, accountData) {
    try {
      commit("loading");
      const result = await Accounts.createBankAccount(accountData);
      commit("setAccounts", result.data.results);
    } catch (error) {
      commit("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async updateAccountName({ commit, dispatch }, accountData) {
    try {
      commit("loading");
      await Accounts.updateAccountName(
        accountData.accountId,
        accountData.account
      );
      dispatch("getAccounts");
      dispatch("notifications/success", "Account has been updated", {
        root: true,
      });
    } catch (error) {
      dispatch(
        "notifications/error",
        { message: "Failed to update account name", error },
        { root: true }
      );
      commit("loading", false);
    }
  },

  async refresh({ dispatch }) {
    dispatch("getAccounts");
  },
};
