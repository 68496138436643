import { Module, ActionTree, MutationTree } from "vuex";
import { RootState } from "../types";
import { Utils } from "@/utils";

const namespaced = true;

export interface Status {
  success: boolean | string;
  error: boolean | string;
}

export interface NotificationState {
  status: Status;
}

export const state: NotificationState = {
  status: { success: false, error: false },
};

export const actions: ActionTree<NotificationState, RootState> = {
  async success({ commit }, message) {
    commit("success", message);
  },
  async error({ commit }, payload) {
    if (
      payload.response &&
      payload.response.data &&
      payload.response.data.message
    ) {
      commit("error", payload.response.data.message);
      Utils.logSentry(payload);
    } else if (payload.message) {
      const constructedMessage = `${payload.message}: ${
        payload.error.response.data.message || payload.error.response.data.error
      }`;
      commit("error", constructedMessage);
      Utils.logSentry(constructedMessage);
    } else {
      commit("error", payload);
      Utils.logSentry(payload);
    }
  },
  async clearStatus({ commit }) {
    commit("clearStatus");
  },
};

export const mutations: MutationTree<NotificationState> = {
  success(state, payload) {
    state.status.success = payload;
  },
  error(state, payload) {
    state.status.error = payload;
  },
  clearStatus(state) {
    state.status.error = false;
    state.status.success = false;
  },
};

export const notifications: Module<NotificationState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
};
