<template>
  <div id="app">
    <!-- If user is logged in and shop has been selected the main content of the app would be displayed-->
    <v-app
      id="inspire"
      v-if="isAuthenticated && activeShopToken && !switchShop"
    >
      <v-navigation-drawer
        class="secondary"
        dark
        fixed
        :clipped="$vuetify.breakpoint.mdAndUp"
        app
        v-model="drawer"
      >
        <S2SNavigationMenus :config="menuConfig"></S2SNavigationMenus>
      </v-navigation-drawer>
      <toolbar :drawer.sync="drawer"></toolbar>
      <v-main class="background">
        <notifications></notifications>
        <router-view></router-view>
      </v-main>
    </v-app>
    <!-- Might consider adding an additional check here if there is no id in localstore(active-shop-id) -->
    <!-- If user is logged but didn't select a shop yet, he would get a list of shops -->
    <v-app
      v-else-if="
        isAuthenticated &&
        !activeShop &&
        !loading &&
        myShops &&
        myShops.length > 0
      "
    >
      <v-main>
        <v-dialog value="true" max-width="500px" light scrollable persistent>
          <S2SCard title="Pick Shop" style="overflow-y: auto">
            <v-text-field
              label="Filter By Shop Name or Code"
              v-model="filterVal"
              append-icon="search"
              @keydown.enter="newSearch"
              @click:append="newSearch"
              persistent-hint
              hint="Shop not found? Just click the search icon or hit enter."
            ></v-text-field>

            <v-list>
              <v-fade-transition group>
                <v-list-item
                  ripple
                  @click="onShopSelected(shop)"
                  v-for="shop in filterByNameOrCode()"
                  :key="shop.id"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="shop.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-fade-transition>
            </v-list>
          </S2SCard>
        </v-dialog>
      </v-main>
    </v-app>
    <v-app v-else-if="isAuthenticated && !activeShop && !myShops.length">
      <v-navigation-drawer
        class="secondary"
        dark
        fixed
        :clipped="$vuetify.breakpoint.mdAndUp"
        app
        v-model="drawer"
      >
        <S2SNavigationMenus :config="menuConfig"></S2SNavigationMenus>
      </v-navigation-drawer>
      <toolbar :drawer.sync="drawer"></toolbar>
      <v-main class="background">
        <v-card class="text-center pa-5">
          <v-card-title class="justify-center"
            >User not linked to an organisation</v-card-title
          >
          <v-card-text
            >Please email
            <a href="mailto:support@shoptoshop.co.za"
              >support@shoptoshop.co.za</a
            ></v-card-text
          >
          <v-card-actions class="justify-center">
            <v-btn color="accent" @click="logout()">Logout</v-btn>
          </v-card-actions>
        </v-card>
      </v-main>
    </v-app>
    <!-- In the process of requesting authentication we display a loading circle -->
    <v-app v-else>
      <v-container fluid fill-height>
        <v-layout align-center justify-center column fill-height>
          <v-progress-circular
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
          <div class="headline">Loading...</div>
        </v-layout>
      </v-container>
    </v-app>
  </div>
</template>

<script>
  import Vue from "vue";
  import Toolbar from "@/components/Toolbar.vue";
  import Notifications from "@/components/Notifications.vue";
  import { Shop } from "@/store/shops/types";
  import menuConfig from "./static/config/nav-menu-config.json";

  export default Vue.extend({
    name: "App",

    components: { Toolbar, Notifications },

    data: function () {
      return {
        drawer: false, // Determine if nav drawer is open or closed
        filterVal: "",
        menuConfig: menuConfig,
        navRoutes: { to: { name: "" }, from: { name: "" } },
      };
    },

    computed: {
      isAuthenticated: function () {
        return this.$store.state.auth.isAuthenticated;
      },
      getLoggedInUser: function () {
        return this.$store.getters["auth/getLoggedInUser"];
      },
      activeShop: function () {
        return this.$store.state.shops.activeShop;
      },
      activeShopToken: function () {
        return this.$store.state.shops.activeShopToken;
      },
      myShops: function () {
        return this.$store.state.shops.myShops;
      },
      loading: function () {
        return this.$store.state.shops.loading;
      },
      switchShop: function () {
        return this.$store.state.shops.switchShop;
      },
    },

    watch: {
      $route: function (to, from) {
        this.navRoutes = {
          to: to,
          from: from,
        };
      },
      myShops: function (shops) {
        const activeStoreId = localStorage.getItem("active-shop-id");
        if (!shops || shops.length < 1 || !activeStoreId) return;

        for (let i = 0; i < shops.length; i++) {
          if (shops[i].id === +activeStoreId) {
            this.$store.dispatch("shops/setActiveShop", shops[i].id);
            break;
          }
        }

        if (this.activeShop == null) {
          // set active shop
          this.$store.dispatch("shops/setActiveShop", +activeStoreId);
        }
      },
      activeShopToken: function () {
        this.$store.dispatch("accounts/fetchBalance");
        this.$store.dispatch("accounts/getTransferDepositAccounts");
        this.$store.dispatch("wsconnections/wsConnection");
      },
      isAuthenticated: function (val) {
        if (val) this.$store.dispatch("shops/fetchMyShops", this.filterVal);
      },
    },

    mounted: function () {
      this.$store.dispatch("auth/authRequest");
    },

    methods: {
      filterByNameOrCode() {
        return this.myShops.filter((shop) => {
          return (
            shop.name.toLowerCase().indexOf(this.filterVal.toLowerCase()) !==
              -1 ||
            shop.code.toLowerCase().indexOf(this.filterVal.toLowerCase()) !== -1
          );
        });
      },
      onShopSelected(shop) {
        this.$store.dispatch("shops/setActiveShop", shop.id);
      },
      newSearch() {
        this.$store.dispatch("shops/fetchMyShops", this.filterVal);
      },
      logout() {
        this.$store.dispatch("auth/logout");
      },
    },
  });
</script>

<style>
  #app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  .currency-width {
    min-width: 200px;
  }
</style>
