import { store } from "@/store";
import Axios from "axios";
import { Filters, Providers, DeliveryRestrictions } from "@/store/orders/types";
import { generateUrl, generateHeader } from "./http-common";
import { Paging } from "@/store/utils";

export type OrderStatus =
  | "collected"
  | "ready"
  | "delivered"
  | "declined"
  | "in_transit"
  | "accept"
  | "cancelled"
  | "pending";
export class OrdersApiService {
  private static transformRequestOptions(params: any) {
    let options = "";
    for (const key in params) {
      if (typeof params[key] !== "object") {
        if (!params[key]) continue;
        options += `${key}=${params[key]}&`;
      } else if (typeof params[key] === "object" && params[key].length) {
        params[key].forEach((el: any) => {
          options += `${key}=${el}&`;
        });
      }
    }
    return options ? options.slice(0, -1) : options;
  }

  public static async updateOrderItem(
    orderId: number,
    orderItemId: number,
    orderItemBody: any
  ) {
    return Axios.put(
      generateUrl(`orders/${orderId}/update/item/${orderItemId}`),
      { ...orderItemBody },
      {
        headers: await generateHeader(),
      }
    );
  }

  public static async addOrderGrn(orderId: number, grn: string) {
    return Axios.put(
      generateUrl(`orders/${orderId}/grn`),
      { grn },
      {
        headers: await generateHeader(),
      }
    );
  }

  public static async fetchOrders(paging: Paging, filters: Filters) {
    return Axios.get(
      generateUrl(
        `orders/organisation/${store.state.shops.activeShop.id}/summary`
      ),
      {
        params: {
          page: paging.page,
          pagesize: paging.itemsPerPage,
          ...filters,
        },
        paramsSerializer: (params) =>
          OrdersApiService.transformRequestOptions(params),
        headers: await generateHeader(),
      }
    );
  }

  public static async fetchOrder(orderId: number) {
    return Axios.get(generateUrl(`orders/${orderId}`), {
      headers: await generateHeader(),
    });
  }

  public static async fetchOrderPickupAddress() {
    return Axios.get(
      generateUrl(`organisations/${store.state.shops.activeShop.id}/locations`),
      {
        headers: await generateHeader(),
      }
    );
  }

  public static async fetchDashboardData(group: string) {
    return Axios.get(
      generateUrl(
        `orders/overview/organisation/${store.state.shops.activeShop.id}`
      ),
      {
        params: {
          groupBy: group,
        },
        headers: await generateHeader(),
      }
    );
  }

  // TODO: This if statement is one level to low. It should live in the middleware
  public static async changeOrderStatus(
    id: number,
    statusType: OrderStatus,
    params: any = { message: "" }
  ) {
    if (statusType === "delivered" || statusType === "pending")
      return Axios.put(
        generateUrl(`orders/${id}/status/${statusType}`),
        { ...params },
        {
          headers: await generateHeader(),
        }
      );
    else
      return Axios.put(
        generateUrl(
          `orders/${id}/organisation/${store.state.shops.activeShop.id}/status/${statusType}`
        ),
        { ...params },
        {
          headers: await generateHeader(),
        }
      );
  }

  public static async declineOrder(orderId: number, message = "") {
    return Axios.put(
      generateUrl(
        `orders/${orderId}/organisation/${store.state.shops.activeShop.id}/status/declined`
      ),
      { message },
      {
        headers: await generateHeader(),
      }
    );
  }

  public static async fetchOrderStatusses() {
    return Axios.get(generateUrl(`orderstatus`), {
      headers: await generateHeader(),
    });
  }

  public static async fetchOrderStatusOverview(
    organisationId: number,
    paymentStatus?: Array<string>,
    orgsearch?: string
  ) {
    let status = "";

    if (paymentStatus != null) {
      paymentStatus.forEach((item: string) => {
        status += `&paymentstatus=${item}`;
      });
    }

    if (orgsearch != null || orgsearch === "") {
      status += `&orgsearch=${orgsearch}`;
    }

    return Axios.get(
      generateUrl(
        `orders/organisation/${organisationId}/summary/count?orderstatus=2&orderstatus=3&orderstatus=5&orderstatus=6${status}`
      ),
      {
        headers: await generateHeader(),
      }
    );
  }

  public static async fetchDeliverySettings() {
    return Axios.get(
      generateUrl(
        `deliveries/organisation/${store.state.shops.activeShop.id}/settings`
      ),
      {
        headers: await generateHeader(),
      }
    );
  }

  public static async fetchDeliveryRestrictions() {
    return Axios.get(
      generateUrl(
        `organisations/${store.state.shops.activeShop.id}/delivery/restriction`
      ),
      {
        headers: await generateHeader(),
      }
    );
  }

  public static async updateDeliveryRestrictions(
    restrictions: DeliveryRestrictions
  ) {
    return Axios.put(
      generateUrl(
        `organisations/${store.state.shops.activeShop.id}/delivery/restriction`
      ),
      { ...restrictions },
      {
        headers: await generateHeader(),
      }
    );
  }

  public static async updateDeliveryFee(fee: number) {
    return Axios.put(
      generateUrl(
        `deliveries/organisation/${store.state.shops.activeShop.id}/settings`
      ),
      { fee },
      {
        headers: await generateHeader(),
      }
    );
  }

  public static async updateRequireBoxes(requireBoxes: boolean) {
    return Axios.put(
      generateUrl(
        `deliveries/organisation/${store.state.shops.activeShop.id}/settings`
      ),
      { requireBoxes },
      {
        headers: await generateHeader(),
      }
    );
  }

  public static async updateDeliveryProvider(provider: Providers) {
    return Axios.put(
      generateUrl(
        `deliveries/organisation/${store.state.shops.activeShop.id}/settings`
      ),
      { ...provider },
      {
        headers: await generateHeader(),
      }
    );
  }

  public static async fetchProviders() {
    return Axios.get(generateUrl(`deliveries/providers`), {
      headers: await generateHeader(),
    });
  }

  public static async fetchBoxSizes() {
    return Axios.get(generateUrl(`deliveries/boxes`), {
      headers: await generateHeader(),
    });
  }

  public static async fetchAllowedBoxSizes() {
    return Axios.get(
      generateUrl(
        `deliveries/organisation/${store.state.shops.activeShop.id}/boxes`
      ),
      {
        headers: await generateHeader(),
      }
    );
  }

  public static async addBoxSizes(boxId: number) {
    return Axios.put(
      generateUrl(
        `deliveries/organisation/${store.state.shops.activeShop.id}/boxes/${boxId}`
      ),
      {},
      {
        headers: await generateHeader(),
      }
    );
  }

  public static async removeBoxSizes(boxId: number) {
    return Axios.delete(
      generateUrl(
        `deliveries/organisation/${store.state.shops.activeShop.id}/boxes/${boxId}`
      ),
      {
        headers: await generateHeader(),
      }
    );
  }
}
