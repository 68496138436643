import { GetterTree } from "vuex";
import { AccountsState, Account } from "@/store/accounts/types";
import { RootState } from "@/store/types";

export const getters: GetterTree<AccountsState, RootState> = {
  shopAccount(state): Account {
    for (let account of state.accounts) {
      if (account.methodKey === "system") return account;
    }

    return {} as Account;
  },
  bankAccounts(state): Account[] {
    return state.transferDepositAccounts.filter((account) => {
      return account.methodKey === "bank_account";
    });
  },
  accounts(state): Account[] {
    return state.accounts;
  },
  total(state): number {
    return state.accounts.length;
  },
};
